import React, { useState, useEffect, useRef } from 'react';
import styles from "./EditarEventoForm.module.scss";
import WhatsAppPop from "../../../Components/Buttons-loaders-inputs/WhatsAppPop/WhatsAppPop";
import LocationSearchInput from "../../../Components/Buttons-loaders-inputs/InputBuscarUbicacion-GoogleMaps/LocationSearchInput";
import axios from "axios";
import Alert from "../../../Components/Alert/Alert";
import SubmitButton from "../../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import { useNavigate } from "react-router-dom";
import NoPermissionPage from '../../../Components/NoPermissionPage/NoPermissionPage';
import Variables from '../../../Context/Variables';
import { Icon } from '@iconify/react/dist/iconify.js';
import EditarEventoFormSkeleton from './Skeleton/EditarEventoFormSkeleton'

// Agregar la función para calcular bytes
function calculateByteSize(string) {
  return new Blob([string]).size;
}

const EditarEventoForm = ({ eventoId, jwt }) => {

  //ESTADOS PARA GESTIONAR LA AUTENTICACION Y EL BOTON SUBMIT
  const [isLoading, setIsLoading] = useState(false);

  // ESTADOS PARA INPUTS
  const estadoInicialRef = useRef({});
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [mayoresEdad, setMayoresEdad] = useState(false);
  const [ubicacion, setUbicacion] = useState("");
  const [aceptaTerminos, setAceptaTerminos] = useState(false);
  const [imagenEvento, setImagenEvento] = useState(null);
  const [imagenUrl, setImagenUrl] = useState("");
  const [distribucionDePagos, setDistribucionDePagos] = useState(false);
  const [organizacion, setOrganizacion] = useState("");
  const [fechaYHora, setfechaYHora] = useState("");
  const [ubicacionInicial, setUbicacionInicial] = useState("");
  const [habilitadoParaVenta, setHabilitadoParaVenta] = useState(false);

  //estado para controlar si el componente esta cargando
  const [cargando, setCargando] = useState(true);

  //permisos para editar
  const [tienePermisos, setTienePermisos] = useState(true);

  //Navegador
  const navigate = useNavigate();

  //estado para manejar errores al buscar ubicacion con google
  const [errorUbicacion, setErrorUbicacion] = useState("");

  // Estados para validaciones
  const [nombreValido, setNombreValido] = useState(false);
  const [descripcionValida, setDescripcionValida] = useState(false);
  const [ubicacionValida, setUbicacionValida] = useState(false);
  const [organizacionValida, setOrganizacionValida] = useState(false);

  const [habilitarConsumiciones, setHabilitarConsumiciones] = useState(false);
  const [habilitarButacas, setHabilitarButacas] = useState(false);
  const [tieneMenu, setTieneMenu] = useState(false);
  const [tieneButacas, setTieneButacas] = useState(false);

  //formData para guardar solo los campos que hayn sido modificados
  const newFormData = useRef(new FormData());

  //Errores de los inputs que van a mostrarse al usuario
  const [errores, setErrores] = useState({
    nombre: "",
    descripcion: "",
    ubicacion: ""
  });

  //estado para determinar si el formulario completo es valido o no
  const [formularioValido, setFormularioValido] = useState(false);

  // Agregar este estado nuevo
  const [mercadoPagoVinculado, setMercadoPagoVinculado] = useState(false);

  //useEffect para subir a lo alto de la pagina en el inicio
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //BUSCAR EL EVENTO Y TRAER LOS DATOS A LOS INPUTS
  useEffect(() => {
    if (eventoId && jwt) {
      const obtenerDatosEvento = async () => {
        const config = {
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        };

        // Realiza la solicitud GET con axios, incluyendo el encabezado de autorización
        try {
          setCargando(true);

          const response = await axios.get(Variables.API_BASE_URL + `/api/eventos/obtener-info/${eventoId}`, config);

          const evento = response.data;

          // Guardar el estado inicial del evento en el useRef
          estadoInicialRef.current = {
            nombre: evento.nombre,
            descripcion: evento.descripcion,
            mayoresEdad: evento.habilitadoParaVenta,
            organizacion: evento.productor,
            ubicacion: evento.googleMapUrl.split('/')[0],
            imagenUrl: evento.imagenUrl,
            distribucionDePagos: evento.distribucionDePagos,
            habilitadoParaVenta: evento.habilitadoParaVenta,
            fechaYHora: evento.fechaRealizacion,
            habilitarConsumiciones: evento.habilitarConsumiciones,
            habilitarButacas: evento.habilitarButacas,
          };


          // Actualizar tus estados con los datos obtenidos
          setNombre(evento.nombre);

          setDescripcion(evento.descripcion);

          setMayoresEdad(evento.habilitadoParaVenta);

          setHabilitarButacas(evento.habilitarButacas)
          setHabilitarConsumiciones(evento.habilitarConsumiciones)
          setTieneMenu(evento.tieneMenu)
          setTieneButacas(evento.tieneButacas)
          setDistribucionDePagos(evento.distribucionDePagos)
          const ubicacionParts = evento.googleMapUrl.split('/');
          console.log("UBICACION", evento.googleMapUrl);
          setUbicacionInicial(ubicacionParts[0]);

          setImagenUrl(evento.imagenUrl);

          setOrganizacion(evento.productor);

          setHabilitadoParaVenta(evento.habilitadoParaVenta);

          setfechaYHora(evento.fechaRealizacion);

          setAceptaTerminos(evento.aceptaTerminos);

          setMercadoPagoVinculado(evento.mercadoPagoVinculado);

          setCargando(false)
        } catch (error) {
          setCargando(false);

          if (error.response && error.response.status === 401) {
            console.error('Acceso denegado. No tienes permiso para acceder a esta información.');

            setTienePermisos(false);

          } else {
            setCargando(false);
            console.error('Ocurrió un error al obtener la información del evento:', error);
          }
        }
      };
      obtenerDatosEvento();
    }
  }, [eventoId, jwt]);

  //METODO PARA VERIFICAR VALIDEZ DEL FORMULARIO COMPLETO EN BASE A LOS ESTADOS DE TODOS LOS INPUTS
  const verificarFormulario = () => {
    const empty = isFormDataEmpty();
    // Verifica la validez de los demás campos del formulario
    const esFormularioValido = (!empty);

    setFormularioValido(esFormularioValido);
  };

  useEffect(() => {

    for (let [key, value] of newFormData.current.entries()) {
      console.log(`${key}: ${value}`);
    }

    verificarFormulario();
  }, [newFormData, verificarFormulario]);

  const imprimirForm = () => {
    for (let [key, value] of newFormData.current.entries()) {
      console.log(`${key}: ${value}`);
    }
  }

  useEffect(() => {
    verificarFormulario();
  }, [isFormDataEmpty]);


  function isFormDataEmpty(formDataRef) {

    const formData = newFormData.current;

    for (let entry of formData.entries()) {
      return false;
    }

    return true;
  }

  //DETERMINA SI EL BOTON TIENE QUE ESTAR HABILITADO O NO EN BASE A SI EL FORM ES VALIDO O NO
  const isButtonDisabled = !formularioValido;

  //HANDLERS INDIVIVDUALES DE CADA INPUT
  const handleNombreChange = (e) => {
    const { value } = e.target;
    const isValid = value.trim().length >= 10;
    setNombre(value);
    setErrores({
      ...errores,
      nombre: isValid ? "" : "El nombre debe tener al menos 10 caracteres.",
    });
    setNombreValido(isValid);

    // Verifica el formulario después de actualizar el estado
    verificarFormulario();

    // Actualiza formData solo si el nombre es válido
    if (isValid) {
      newFormData.current.set('nombre', value);
      imprimirForm();
    }
  };

  const handleDescripcionChange = (e) => {
    const { value } = e.target;
    const byteSize = calculateByteSize(value);
    const isValid = value.trim().length >= 20 && byteSize <= 1020;

    setDescripcion(value);

    if (byteSize > 1020) {
        setErrores((prev) => ({
            ...prev,
            descripcion: "La descripción supera el límite de espacio permitido.",
        }));
    } else if (value.length >= 255) {
        setErrores((prev) => ({
            ...prev,
            descripcion: "Has alcanzado el límite máximo de caracteres.",
        }));
    } else {
        setErrores((prev) => ({
            ...prev,
            descripcion: isValid ? "" : "La descripción debe tener al menos 20 caracteres.",
        }));
    }

    setDescripcionValida(isValid && byteSize <= 1020);
  };

  const handleOrganizacionChange = (e) => {
    const valor = e.target.value;
    setOrganizacion(valor);
    const isValid = valor.trim().length > 4; // Define lo que consideras una organización válida

    // Actualiza el estado de errores y validación
    if (isValid) {
      setOrganizacionValida(true);
      setErrores((prev) => ({ ...prev, organizacion: "" }));
    } else {
      setOrganizacionValida(false);
      setErrores((prev) => ({
        ...prev,
        organizacion: "La organización debe tener más de 4 caracteres.",
      }));
    }

    // Actualiza formData solo si la organización es válida
    if (isValid) {
      newFormData.current.set('productor', valor);
      imprimirForm();
    }

    // Verifica el formulario después de actualizar el estado
    verificarFormulario();
  };

  const handleLocationSelect = (place) => {
    // Verifica si el lugar seleccionado tiene una geometría (ubicación)
    if (place && place.mapsUrl) {
      // Actualiza el estado con el nombre del lugar y la URL de Google Maps
      const ubicacionCompleta = `${place.address}/${place.mapsUrl}`;
      setUbicacion(ubicacionCompleta);
      setErrorUbicacion(""); // Limpia cualquier error previo
      setUbicacionValida(true); // Indica que la ubicación es válida

      // Actualiza formData con la nueva ubicación
      newFormData.current.set('ubicacion', ubicacionCompleta);
      imprimirForm();
    } else {
      // Establece un mensaje de error si la selección es inválida
      setErrorUbicacion("Por favor, selecciona una ubicación válida.");
      setUbicacionValida(false); // Indica que la ubicación no es válida
    }

    // Verifica el formulario después de actualizar el estado
    verificarFormulario();
  };

  const handleMayoresEdadChange = (e) => {
    const { checked } = e.target;
    setMayoresEdad(checked);

    // Actualiza formData con el nuevo estado
    newFormData.current.set('esParaMayores', checked ? "true" : "false");
    imprimirForm();

    // Verificar la validez del formulario si es necesario
    verificarFormulario();

  };

  const handleHabilitadoParaVentaChange = (e) => {
    const { checked } = e.target;
    setHabilitadoParaVenta(checked);

    // Actualiza formData con el nuevo estado
    newFormData.current.set('habilitadoParaVenta', checked ? "true" : "false");
    imprimirForm();
    // Verificar la validez del formulario si es necesario
    verificarFormulario();
  };
  const handleHabilitarConsumicionesChange = (e) => {
    const { checked } = e.target;
    setHabilitarConsumiciones(checked);

    // Actualiza formData con el nuevo estado
    newFormData.current.set('habilitarConsumiciones', checked ? "true" : "false");
    imprimirForm();

    // Verificar la validez del formulario si es necesario
    verificarFormulario();
  };

  const handleHabilitarButacasChange = (e) => {
    const { checked } = e.target;
    setHabilitarButacas(checked);

    // Actualiza formData con el nuevo estado
    newFormData.current.set('habilitarButacas', checked ? "true" : "false");
    imprimirForm();

    // Verificar la validez del formulario si es necesario
    verificarFormulario();
  };


  const handleFechaYHoraChange = (event) => {
    const { value } = event.target;
    setfechaYHora(value);

    // Actualiza formData con la nueva fecha y hora
    newFormData.current.set('fechaYHora', value);
    imprimirForm();

    // Verificar la validez del formulario si es necesario
    verificarFormulario();
  };

  const handleImagenEventoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      if (file.size > 1.5 * 1024 * 1024) {
        // Si el archivo supera el límite de tamaño
        setErrores(prev => ({ ...prev, imagenEvento: "El archivo no debe exceder 1.5 MB." }));
      } else {
        setImagenEvento(file);
        setErrores(prev => ({ ...prev, imagenEvento: "" }));

        // Actualiza formData con la imagen seleccionada
        newFormData.current.set('imagen', file);
        imprimirForm();
      }
    } else {
      // No se seleccionó ningún archivo
      setErrores(prev => ({ ...prev, imagenEvento: "No se ha seleccionado ningún archivo." }));
    }
  };

  const handleAceptaTerminosChange = (e) => {
    const { checked } = e.target;
    setAceptaTerminos(checked);

    // Actualizar mensaje de error si es necesario
    setErrores(prevErrores => ({
      ...prevErrores,
      aceptaTerminos: checked ? "" : "Debes aceptar los términos y condiciones para continuar.",
    }));

    // Actualiza formData con la nueva aceptación de términos
    newFormData.current.set('aceptaTerminos', checked ? "true" : "false");
    imprimirForm();
    verificarFormulario();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    imprimirForm();

    setIsLoading(true);
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
      };
      // Añade los valores de consumiciones y butacas al FormData
      newFormData.current.set('habilitarConsumiciones', habilitarConsumiciones ? "true" : "false");
      newFormData.current.set('habilitarButacas', habilitarButacas ? "true" : "false");

      const response = await axios.post(
        (Variables.API_BASE_URL + `/api/eventos/actualizarEvento/${eventoId}`),
        newFormData.current,
        config
      );

      Alert({
        tipo: "success",
        titulo: "¡Éxito!",
        descripcion: "Evento actualizado con éxito",
        duracion: 3000,
        onClose: () => {
          window.location.reload();
        }
      });

    } catch (error) {
      // Procesamiento de errores
      let errorMsg = "No se pudo actualizar el evento. Intente nuevamente.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      }
      Alert({
        tipo: "error",
        titulo: "Error",
        descripcion: errorMsg,
        duracion: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (cargando) {
    return (
      <EditarEventoFormSkeleton />
    );
  }

  // Si no tiene permisos, renderiza el componente <NoPermissionPage />
  if (!tienePermisos) {
    return <NoPermissionPage />;
  }

  return (
    <div className={styles.bodyCrearEvento}>
      <section className={styles.eventoFoto}>
        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/search.png" alt=''></img>
      </section>

      <div className={styles.contForm}>
        <h2>Editar evento</h2>
        <h4 className={styles.subtitulo}>
          Acá podes ver la información de tu evento y editarla según tus preferencias. Hay campos obligatorios.
        </h4>
        <form onSubmit={handleSubmit}>
          {/* Nombre del evento */}
          <div>
            <label>Nombre del evento:</label>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre del Evento"
              value={nombre}
              onChange={handleNombreChange}
              className={
                errores.nombre ? styles.borderInvalid : styles.borderValid
              }
            />
            {errores.nombre && <p className={styles.error}>{errores.nombre}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Descripción */}
          <div className={styles.contenedorInfo}>
            <label>Descripción:</label>
            <textarea
              name="descripcion"
              placeholder="Descripción del Evento"
              value={descripcion}
              maxLength={255}
              onChange={handleDescripcionChange}
              className={errores.descripcion ? styles.borderInvalid : styles.borderValid}
            />
            <div className={styles.caracteresRestantes}>
                <small className={descripcion.length >= 255 ? styles.error : ''}>
                    {`${descripcion.length}/255 caracteres`}
                </small>
            </div>
            {errores.descripcion && <p className={styles.error}>{errores.descripcion}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          <div>
            <label>Organización o productor del evento:</label>
            <input
              type="text"
              name="organizacion"
              placeholder="Nombre de la organización o productor"
              value={organizacion}
              onChange={handleOrganizacionChange}
              className={
                errores.organizacion ? styles.borderInvalid : styles.borderValid
              }
            />
            {errores.organizacion && (
              <p className={styles.error}>{errores.organizacion}</p>
            )}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Mayoría de edad */}
          <h3 className={styles.subtitulo2}>¿Es para mayores de edad?:</h3>
          <div className={`${styles.containerCheckbox} ${styles.mayoresCont}`}>
            <label>
              <input
                id="mayoresEdad"
                type="checkbox"
                name="mayoresEdad"
                checked={mayoresEdad}
                onChange={handleMayoresEdadChange}
              />
              <svg viewBox="0 0 64 64" height="2em" width="2em">
                <path
                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                  className={styles.path}
                ></path>
              </svg>
              <span>Si, es para mayores de 18.</span>
            </label>
          </div>

          <section className={styles.lineaDivisora}></section>

          <label>Habilitado para venta: </label>
          <div className={styles.checkboxApple}>
            <input
              className={styles.yep}
              id="checkApple"
              type="checkbox"
              checked={habilitadoParaVenta}
              onChange={handleHabilitadoParaVentaChange}
            />
            <label className={styles.labelToggle} htmlFor="checkApple"></label>
          </div>
          {/* Checkbox para habilitar consumiciones */}
          {tieneMenu ?
            <div className={styles.checkboxWrapper}>
              <label>Habilitar ventas de consumiciones:</label>
              <div className={styles.checkboxApple}>
                <input
                  className={styles.yep}
                  id="checkConsumiciones"
                  type="checkbox"
                  checked={habilitarConsumiciones}
                  onChange={handleHabilitarConsumicionesChange}
                />
                <label className={styles.labelToggle} htmlFor="checkConsumiciones"></label>
              </div>
            </div>
            :
            null
          }

          {tieneButacas ?
            <div className={styles.checkboxWrapper}>
              <label>Habilitar ventas de butacas:</label>
              <div className={styles.checkboxApple}>
                <input
                  className={styles.yep}
                  id="checkButacas"
                  type="checkbox"
                  checked={habilitarButacas}
                  onChange={handleHabilitarButacasChange}
                />
                <label className={styles.labelToggle} htmlFor="checkButacas"></label>
              </div>
            </div>
            :
            null
          }
          <section className={styles.lineaDivisora}></section>

          {/* Integración con Google Maps para ubicación aquí */}
          <div>
            <label>
              Ubicación:
            </label>
            <div>
              <h3 className={styles.ubiActual}><b>Ubicacion actual:</b> {ubicacionInicial}</h3>
            </div>
            <h3 className={styles.ubiNueva}>
              <b>Remplazar por nueva ubicación:</b> <small>(Busca la direccón exacta de tu evento)</small>:
            </h3>
            <LocationSearchInput onLocationSelect={handleLocationSelect} />
            {errorUbicacion && <p className={styles.error}>{errorUbicacion}</p>}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* FECHA DEL EVENTO */}
          <div>
            <label>Fecha y hora del evento:</label>
            <input
              type="datetime-local"
              value={fechaYHora}
              onChange={handleFechaYHoraChange}
              min="1"
              max="10"
            />
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Carga de imagen */}
          <div>
            <label>Imagen del evento:</label>
            <p className={styles.subtituloImg}>Imagen actual: </p>
            <img className={styles.imgEvento} alt='' src={imagenUrl}></img>
            <p className={styles.subtituloImg}>Cambiar imagen:</p>
            <small>Por favor suba una imagen con dimensiones cuadradas (misma altura y ancho).</small>
            <input
              type="file"
              name="imagenEvento"
              onChange={handleImagenEventoChange}
            />
            {/* Muestra el mensaje de error si existe */}
            {errores.imagenEvento && (
              <p className={styles.error}>{errores.imagenEvento}</p>
            )}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Aceptar términos y condiciones */}

          <div className={styles.containerCheckbox}>
            <label className={styles.container}>
              <input
                type="checkbox"
                value={aceptaTerminos}
                checked={aceptaTerminos}
                onChange={handleAceptaTerminosChange}
              />
              <svg viewBox="0 0 64 64" height="2em" width="2em">
                <path
                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                  className={styles.path}
                ></path>
              </svg>
              <span>
                He leído y acepto los{" "}
                <a
                  href="URL_A_TUS_TERMINOS_Y_CONDICIONES"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.termsLink}
                >
                  términos y condiciones
                </a>{" "}
                de crear un evento de plan 1 con simplepass.
              </span>
            </label>
            {/* Renderizar el mensaje de error si existe */}
            {errores.aceptaTerminos && (
              <p className={styles.error}>{errores.aceptaTerminos}</p>
            )}
          </div>

          <section className={styles.lineaDivisora}></section>

          {/* Nueva sección de Mercado Pago */}
          {distribucionDePagos === "DIRECTO" ?
            <div className={styles.mercadoPagoSection}>
              <h3 className={styles.subtitulo2}>Estado de Mercado Pago:</h3>

              {mercadoPagoVinculado ? (
                <div className={styles.mpVinculado}>
                  <Icon icon="mdi:check-circle" className={styles.checkIcon} width={24} />
                  <div className={styles.mpStatus}>
                    <h4>¡Cuenta de Mercado Pago vinculada correctamente!</h4>
                    <p>Tu cuenta está lista para recibir pagos a tu cuenta.</p>
                  </div>
                </div>
              ) : (
                <div className={styles.mpNoVinculado}>
                  <h3>
                    Para recibir los pagos directamente en tu cuenta, simplemente <b>presiona el botón</b> y <b>accede</b> a tu cuenta de <b>Mercado Pago</b>. Si aún no tienes una cuenta, podrás crearla en el proceso. Finalmente, <b>haz clic en "Permitir"</b> para completar la vinculación.
                  </h3>
                  <a
                    href={`https://auth.mercadopago.com.ar/authorization?client_id=3217027104832057&response_type=code&platform_id=mp&redirect_uri=https://simplepass.com.ar/api/mp/auth&state=${eventoId}`}
                    className={styles.botonConectarMp}
                  >
                    <img
                      src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/portfolio/mercadopagoicon.svg"
                      alt="mp logo"
                      className={styles.mpButtonLogo}
                    />
                    Conectar Mercado Pago
                  </a>
                  <p className={styles.mpSecurityNote}>
                    Todos tus datos sensibles son almacenados de manera{" "}
                    <b>segura y encriptada.</b>
                  </p>
                </div>
              )}
            </div>
            :
            null
          }

          <div className={styles.contSubmit}>
            <SubmitButton
              isDisabled={isButtonDisabled}
              color="#99419d" // Color principal de tu tema
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              Actualizar evento <Icon style={{ marginLeft: "4px" }} icon="solar:diskette-outline" width={16} />
            </SubmitButton>
          </div>


        </form>
        <WhatsAppPop />
      </div>
    </div>
  );
};

export default EditarEventoForm;
