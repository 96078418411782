import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './GestionStaff.module.scss';
import variables from "../../Context/Variables";
import Alert from "../../Components/Alert/Alert";
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import './Skeleton/staffSkeleton.css';
import { Icon } from "@iconify/react";
import CardColaborador from '../../Components/CardColaborador/CardColaborador';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const GestionStaff = () => {
    const [staff, setStaff] = useState([]);
    const [newStaffEmail, setNewStaffEmail] = useState('');
    const [newStaffName, setNewStaffName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [mostrarPassword, setMostrarPassword] = useState({});
    const [deletingStaffIds, setDeletingStaffIds] = useState(new Set());
    const navigate = useNavigate();
    const { estaAutenticado, jwt } = useAuth();

    useEffect(() => {
        document.title = "Gestión de Staff";
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!estaAutenticado) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);

            if (details.rol !== "PRODUCTOR") {
                retornarNoPermissionPage();
            }

            fetchStaff(details.jwt);
        }
    }, [navigate, estaAutenticado]);

    const deleteStaff = async (staffId) => {
        const result = await Alert({
            tipo: 'warning',
            titulo: '¿Estás seguro?',
            descripcion: "Esto eliminará al staff seleccionado y todas sus asociaciones.",
            duracion: 0
        });

        if (result) {
            try {
                await axios.delete(`${variables.API_BASE_URL}/api/staff/delete/${staffId}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                });

                await Alert({
                    tipo: 'success',
                    titulo: 'Eliminado!',
                    descripcion: 'El staff ha sido eliminado exitosamente.',
                    duracion: 3000
                });

                // Actualizar la lista de Staff
                setStaff(staff.filter(member => member.id !== staffId));
            } catch (error) {
                await Alert({
                    tipo: 'error',
                    titulo: 'Error!',
                    descripcion: 'No se pudo eliminar el staff. Asegúrese de que tiene permiso para realizar esta acción.',
                    duracion: 4000
                });
            }
        }
    };

    const validarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const retornarNoPermissionPage = () => {
        return <NoPermissionPage />;
    }

    const fetchStaff = async (jwt) => {
        try {
            const response = await axios.get(`${variables.API_BASE_URL}/api/staff/list`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            setStaff(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching staff', error);
            setError('Failed to fetch staff.');
            setLoading(false);
        }
    };

    const addStaff = async () => {
        if (!validarEmail(newStaffEmail)) {
            await Alert({
                tipo: 'warning',
                titulo: 'Email inválido',
                descripcion: 'Por favor, ingresa un email válido antes de continuar.',
                duracion: 3000
            });
            return;
        }

        if (!newStaffName.trim()) {
            await Alert({
                tipo: 'warning',
                titulo: 'Nombre requerido',
                descripcion: 'Por favor, ingresa un nombre antes de continuar.',
                duracion: 3000
            });
            return;
        }

        const crearStaffDTO = {
            email: newStaffEmail,
            nombre: newStaffName
        };

        setIsAdding(true);
        try {
            const response = await axios.post(`${variables.API_BASE_URL}/api/staff/create`, crearStaffDTO, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            if (response.status === 200) {
                await Alert({
                    tipo: 'success',
                    titulo: 'Staff creado',
                    descripcion: 'El miembro del staff ha sido creado exitosamente.',
                    duracion: 3000
                });
                fetchStaff(jwt);
                setNewStaffEmail('');
                setNewStaffName('');
                setError(null);
            } else {
                setError(response.data || `Error: ${response.status}`);
                await Alert({
                    tipo: 'error',
                    titulo: 'Error',
                    descripcion: response.data || 'Error al crear el staff',
                    duracion: 3000
                });
            }
        } catch (error) {
            console.error('Error adding staff', error);
            const errorMessage = error.response?.data || `Error: ${error.message}`;
            setError(errorMessage);
            await Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: errorMessage,
                duracion: 3000
            });
        } finally {
            setIsAdding(false);
        }
    };

    const togglePasswordVisibility = (staffId) => {
        setMostrarPassword(prevState => ({
            ...prevState,
            [staffId]: !prevState[staffId]
        }));
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <LoaderSimplepass />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Gestioná tu equipo de STAFF fácilmente
                    <span>Agrega, elimina y visualiza a todos los miembros de tu staff. Estos son quienes van a escanear tickets y consumiciones.</span></h1>
            </div>

            <div className={styles.addStaffSection}>
                <h2 className={styles.sectionTitle}>Agregar miembro</h2>
                <div className={styles.addStaffForm}>
                    <div className={styles.inputGroup}>
                        <Icon icon="solar:user-bold" />
                        <input
                            type="text"
                            placeholder="Nombre del nuevo Staff"
                            value={newStaffName}
                            onChange={e => setNewStaffName(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <Icon icon="material-symbols:mail-outline" />
                        <input
                            type="email"
                            placeholder="Email del nuevo Staff"
                            value={newStaffEmail}
                            onChange={e => setNewStaffEmail(e.target.value)}
                        />
                    </div>
                    <button
                        onClick={addStaff}
                        disabled={isAdding}
                        className={styles.addButton}
                    >
                        {isAdding ? (
                            <Icon icon="line-md:loading-loop" />
                        ) : (
                            <>Agregar <Icon icon="mingcute:add-fill" /></>
                        )}
                    </button>
                </div>
            </div>

            <h2 className={styles.sectionTitle}>Tu equipo de staffs</h2>
            <div className={styles.staffList}>
                {staff.length > 0 ? (
                    staff.map(staffMember => (
                        <CardColaborador
                            key={staffMember.id}
                            colaborador={{
                                id: staffMember.id,
                                username: staffMember.username,
                                email: staffMember.email,
                                contrasena: staffMember.contrasena
                            }}
                            onUnassign={deleteStaff}
                            isUnassigning={deletingStaffIds.has(staffMember.id)}
                            showPassword={mostrarPassword[staffMember.id]}
                            onTogglePassword={() => togglePasswordVisibility(staffMember.id)}
                            isInTeamManagement={true}
                            onVerInforme={false}
                        />
                    ))
                ) : (
                    <p className={styles.noStaff}>Aún no tienes Staff.</p>
                )}
            </div>

        </div>
    );
};

export default GestionStaff;