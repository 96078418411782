import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './GestionStaffEvento.module.scss';
import variables from "../../Context/Variables";
import Swal from "sweetalert2";
import Alert from '../../Components/Alert/Alert';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';
import CardColaborador from '../../Components/CardColaborador/CardColaborador';

const GestionStaffEvento = () => {
    const [staffAsignado, setStaffAsignado] = useState([]);
    const [staffDisponible, setStaffDisponible] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(true);
    const navigate = useNavigate();
    const { estaAutenticado, jwt } = useAuth();
    const { eventoId, nombre } = useParams();
    const [mostrarPassword, setMostrarPassword] = useState({});
    const [assigningStaffIds, setAssigningStaffIds] = useState(new Set());
    const [unassigningStaffIds, setUnassigningStaffIds] = useState(new Set());

    const decodeStringFromURL = (str) => {
        return decodeURIComponent(str);
    };

    useEffect(() => {
        document.title = "Staff del evento: " + decodeStringFromURL(nombre);
        ensureAuthenticated();
        fetchStaffEvento();
    }, [eventoId, nombre]);

    const ensureAuthenticated = () => {
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);
            if (details.rol !== "PRODUCTOR") {
                setHasPermission(false);
            }
        }
    };

    const fetchStaffEvento = async () => {
        try {
            const [asignadosResponse, disponiblesResponse] = await Promise.all([
                axios.get(`${variables.API_BASE_URL}/api/staff/${eventoId}/staff`, {
                    headers: { Authorization: `Bearer ${jwt}` }
                }),
                axios.get(`${variables.API_BASE_URL}/api/staff/list/noEvento/${eventoId}`, {
                    headers: { Authorization: `Bearer ${jwt}` }
                })
            ]);

            setStaffAsignado(asignadosResponse.data);
            setStaffDisponible(disponiblesResponse.data);
            setLoading(false);
        } catch (error) {
            console.error('Error al cargar el staff:', error);
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudo cargar el staff del evento.'
            });
            setLoading(false);
        }
    };

    const handleAssign = async (staffId) => {
        setAssigningStaffIds(prev => new Set(prev.add(staffId)));
        try {
            await axios.post(`${variables.API_BASE_URL}/api/staff/${eventoId}/assignStaff/${staffId}`, null, {
                headers: { Authorization: `Bearer ${jwt}` }
            });
            fetchStaffEvento();
            Alert({
                tipo: 'success',
                titulo: 'Asignado',
                descripcion: 'Staff asignado al evento exitosamente.'
            });
        } catch (error) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudo asignar el staff al evento.'
            });
        }
        setAssigningStaffIds(prev => {
            const newSet = new Set(prev);
            newSet.delete(staffId);
            return newSet;
        });
    };

    const handleUnassign = async (staffId) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, desasignar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setUnassigningStaffIds(prev => new Set(prev.add(staffId)));
                try {
                    await axios.delete(`${variables.API_BASE_URL}/api/staff/${eventoId}/unassignStaff/${staffId}`, {
                        headers: { Authorization: `Bearer ${jwt}` }
                    });
                    fetchStaffEvento();
                    Alert({
                        tipo: 'success',
                        titulo: 'Desasignado',
                        descripcion: 'El staff ha sido desasignado del evento.'
                    });
                } catch (error) {
                    Alert({
                        tipo: 'error',
                        titulo: 'Error',
                        descripcion: 'No se pudo desasignar el staff del evento.'
                    });
                }
                setUnassigningStaffIds(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(staffId);
                    return newSet;
                });
            }
        });
    };

    const togglePasswordVisibility = (staffId) => {
        setMostrarPassword(prevState => ({
            ...prevState,
            [staffId]: !prevState[staffId]
        }));
    };

    if (!hasPermission) {
        return <NoPermissionPage />;
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <LoaderSimplepass />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>
                    Staff del evento: "{decodeStringFromURL(nombre)}"
                </h1>
                <span>Gestiona el staff asignado a este evento y asigna nuevos miembros desde tu equipo.</span>
            </div>

            <div className={styles.sectionTitle}>Staff Asignado</div>
            <div className={styles.staffList}>
                {staffAsignado.length > 0 ? (
                    staffAsignado.map(staff => (
                        <CardColaborador
                            key={staff.id}
                            colaborador={staff}
                            onUnassign={handleUnassign}
                            isUnassigning={unassigningStaffIds.has(staff.id)}
                            showPassword={mostrarPassword[staff.id]}
                            onTogglePassword={() => togglePasswordVisibility(staff.id)}
                            isAssignedSection={true}
                        />
                    ))
                ) : (
                    <p className={styles.noStaff}>No hay staff asignado a este evento.</p>
                )}
            </div>

            {staffDisponible.length > 0 && (
                <>
                    <div className={styles.sectionTitle}>Staff Disponible</div>
                    <div className={styles.staffList}>
                        {staffDisponible.map(staff => (
                            <CardColaborador
                                key={staff.id}
                                colaborador={staff}
                                onAssign={handleAssign}
                                isAssigning={assigningStaffIds.has(staff.id)}
                                isAssignedSection={false}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default GestionStaffEvento;