import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import styles from './GestionColaboradores.module.scss';

const GestionColaboradores = () => {
    useEffect(() => {
        document.title = "Gestión de Colaboradores";
        window.scroll(0, 0);
    }, []);

    const roles = [
        {
            to: `/gestion-staff`,
            icon: "carbon:group-access",
            texto: "Gestionar STAFF",
            descripcion: "Encargados de escanear tickets y consumiciones"
        },
        {
            to: `/gestion-rrpp`,
            icon: "material-symbols:groups-2-outline",
            texto: "Gestionar RR.PP.",
            descripcion: "Encargados de vender en efectivo y enviar tickets."
        },
        {
            to: `/gestion-cajeros`,
            icon: "ph:cash-register-bold",
            texto: "Gestionar CAJEROS",
            descripcion: "Encargados de vender en las cantinas"
        }
    ];

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Gestiona el STAFF, RRPPs y CAJEROS.</h1>
                <p>Visualiza la lista de cada uno de ellos, administralos de manera facil y agrega nuevos.</p>
            </div>
            <div className={styles.cardContainer}>
                {roles.map((rol, index) => (
                    <Link key={index} to={rol.to} className={styles.card}>
                        <div className={styles.iconWrapper}>
                            <Icon className={styles.icon} icon={rol.icon} />
                        </div>
                        <div className={styles.content}>
                            <h2>{rol.texto}</h2>
                            <p>{rol.descripcion}</p>
                        </div>
                        <Icon className={styles.arrowIcon} icon="material-symbols:chevron-right" />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default GestionColaboradores; 