import React from 'react'
import styles from "./LoaderSimplepass.module.scss"

const LoaderSimplepass = () => {
  return (
    <div className={styles.container}>
        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/logo-spinning-recortado.svg" alt="logo-spinning" className={styles.logo} srcset="" />
        <div className={styles.text}>Cargando...</div>
    </div>
  )
}

export default LoaderSimplepass