import styles from './ConfianEnNosotros.module.scss';
import React from 'react';

const logos = [
  {
    src: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/viale_1.webp",
    alt: "Viale"
  },
  {
    src: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/guiso.webp",
    alt: "Guiso"
  },
  {
    src: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/carnavales.webp",
    alt: "Carnavales"
  },
  {
    src: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/fna.webp",
    alt: "FNA"
  },
  {
    src: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/arizona_1.webp",
    alt: "Arizona"
  },
  {
    src: "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/estudio-marketing.webp",
    alt: "Estudio Marketing"
  }
];

const ConfianEnNosotros = () => {
  return (
    <div className={styles.infoGoogle}>
      <div className={styles.container}>
        <div className={styles.comments}>
          <p className={styles.comment}>Confían en nosotros</p> 
          <div className={styles.imageContainer}>
            <div className={styles.marqueeGroup}>
              {logos.map((logo, index) => (
                <img key={index} src={logo.src} alt={logo.alt} className={styles.image} />
              ))}
            </div>
            <div className={styles.marqueeGroup}>
              {logos.map((logo, index) => (
                <img key={`duplicate-${index}`} src={logo.src} alt={logo.alt} className={styles.image} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfianEnNosotros;