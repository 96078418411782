import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './PanelRegistrosQr.module.scss';
import variables from '../../Context/Variables';
import { Icon } from "@iconify/react";
import axios from 'axios';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import DownloadExcelButton from '../../Components/Buttons-loaders-inputs/ExcelButton/ExcelButton';

const PanelRegistrosQr = () => {
  const { eventoId, nombreEvento } = useParams();
  const [envios, setEnvios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { estaAutenticado, jwt } = useAuth();
  const [noPermission, setNoPermission] = useState(false);
  const [showOptions, setShowOptions] = useState(null);

  useEffect(() => {
    if (!estaAutenticado || !jwt) {
      setNoPermission(true);
      return;
    }

    const fetchEnvios = async (page) => {
      if (eventoId) {
        setLoading(true);
        try {
          const response = await axios.get(
            `${variables.API_BASE_URL}/api/registro/evento/${eventoId}?page=${page}&size=15`,
            {
              headers: {
                Authorization: `Bearer ${jwt}`
              }
            }
          );
          console.log(response.data);
          setEnvios(response.data.content || []);
          setTotalPages(response.data.totalPages);
        } catch (err) {
          setError("No se pudieron cargar los registros. Intenta nuevamente más tarde.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchEnvios(currentPage);
  }, [eventoId, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const toggleOptions = (id) => {
    setShowOptions((prev) => (prev === id ? null : id));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showOptions && !event.target.closest(`.${styles.optionsContainer}`)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  if(noPermission){
    return <NoPermissionPage />
  }

  return (
    <div className={styles.body}>
      <div className={styles.panelContainer}>
        <div className={styles.headerRow}>
          <h1 className={styles.title}>Registros de envíos directos</h1>
          <div className={styles.eventoHeader}>
            <h3 className={styles.eventoTitle}>
              Evento: <span className={styles.eventoName}>{decodeURIComponent(nombreEvento)}</span>
            </h3>
            <DownloadExcelButton id={eventoId} />
          </div>
        </div>
        {loading && <p>Cargando registros...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {!loading && !error && (
          <>
            <div className={styles.contenedortabla}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre usuario</th>
                    <th>Tipo</th>
                    <th>Cantidad</th>
                    <th>Email</th>
                    <th>Fecha</th>
                    <th>Más</th>
                  </tr>
                </thead>
                <tbody>
                  {envios.map((envio) => (
                    <tr key={envio.id}>
                      <td>{envio.id}</td>
                      <td>{envio.nombreCompleto}</td>
                      <td>{envio.tipoTicketNombre}</td>
                      <td>{envio.cantidad}</td>
                      <td>{envio.email}</td>
                      <td>{new Date(envio.fechaYHoraHecho).toLocaleString()}</td>
                      <td>
                        <div className={styles.optionsContainer}>
                          <button
                            className={styles.moreButton}
                            onClick={() => toggleOptions(envio.id)}
                          >
                            <Icon icon="carbon:overflow-menu-horizontal" />
                          </button>
                          {showOptions === envio.id && (
                            <div className={styles.dropdownMenu}>
                              <Link
                                to={`/ver-tickets/${envio.tokenGrupo}/${envio.nombreCompleto}/${envio.dni}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Ver tickets
                              </Link>
                              <Link to={`/detalles-envio/${envio.id}/${eventoId}`}>
                                Reenviar tickets
                              </Link>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationControls}>
              <button onClick={handlePreviousPage} disabled={currentPage === 0}>
                Anterior
              </button>
              <span>
                Página {currentPage + 1} de {totalPages}
              </span>
              <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                Siguiente
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PanelRegistrosQr;
