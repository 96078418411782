import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";  
import EventosContenedor from "../../Components/EventosContenedor/EventosContenedor";
import styles from "./HomeContenedor.module.scss";
import EventosFinalizadosCont from '../../Components/EventosContenedor/EventosFinalizadosCont';
import EventBanner from "../../Components/Banner/Banner";
import CallToAction from "../../Components/CallToAction/CallToAction";
import CaraouselInfo from "../../Components/Carousel/CaraouselInfo";
import ConfianEnNosotros from "../../Components/ConfianEnNosotros/ConfianEnNosotros";

const Home = () => {
  const navigate = useNavigate(); 

  // Modificación de head
  useEffect(() => {
    document.title = "Home simplepass";
  }, []);


  // Manejador para redirigir al usuario
  const handleSecondImageClick = () => {
    navigate("/fiesta-nacional-avicultura");
  };

  return (
    <div className={styles.home}>

      <EventBanner />
      <CaraouselInfo />
      <h2>Próximos eventos</h2>
      <EventosContenedor />
      <h2>Eventos finalizados</h2>
      <EventosFinalizadosCont />
      <ConfianEnNosotros />
      <CallToAction />
    </div>
  );
};

export default Home;
