import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './MisEventos.module.scss';
import EventoCardProductor from '../../Components/EventoCardProductor/EventoCardProductor';
import variables from '../../Context/Variables';
import { useAuth } from '../../Context/AuthProvider';
import Alert from '../../Components/Alert/Alert';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const MisEventos = () => {
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(0);
  const [rrppId, setRrppId] = useState(0);
  const { jwt } = useAuth();

  useEffect(() => {
    document.title = "Mis eventos";
    checkUserPermissions();
  }, []);

  const checkUserPermissions = () => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      renderizarError("No se encontró ningún usuario.");
      return;
    }

    const details = JSON.parse(userDetails);
    const rol = details.rol;

    switch (rol) {
      case "USER":
        renderizarError("Una cuenta con solo permisos de usuarios no puede tener eventos. Créese una cuenta de productor.");
        break;
      case "PRODUCTOR":
        setAuth(2);
        fetchEventos();
        break;
      case "STAFF":
        setAuth(3);
        fetchEventos();
        break;
      case "RRPP":
        setAuth(4);
        fetchEventos();
        break;
      case "CAJERO":
        setAuth(5);
        fetchEventos();
        break;
      default:
        renderizarError("Rol no reconocido");
    }
  };

  const fetchEventos = async () => {
    try {
      const response = await axios.get(variables.API_BASE_URL + "/api/eventos/misEventos", {
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });

      // Aseguramos que eventos sea un array
      const eventosData = Array.isArray(response.data.eventos) ? response.data.eventos : [];
      setEventos(eventosData);
      setLoading(false);
      setRrppId(response.data.usuarioId);
    } catch (error) {
      console.error("Error al obtener la lista de eventos:", error);
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'No se pudieron cargar los eventos.'
      });
      setLoading(false);
      // Inicializamos eventos como array vacío en caso de error
      setEventos([]);
    }
  };

  const renderizarError = (contenido) => {
    return (
      <div className={styles.errorContainer}>
        <p className={styles.errorMessage}>{contenido}</p>
      </div>
    );
  };

  if (loading) {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '40vh'}}>
        <LoaderSimplepass />
      </div>
    );
  }

  return (
    <div className={styles.contenedorEventos}>
      {Array.isArray(eventos) && eventos.length > 0 ? (
        [...eventos].reverse().map((evento) => (
          <EventoCardProductor
            key={evento.id}
            evento={evento}
            auth={auth}
            rrppId={rrppId}
          />
        ))
      ) : (
        <div className={styles.noEventos}>
          <p>No tienes eventos registrados.</p>
        </div>
      )}
    </div>
  );
};

export default MisEventos;