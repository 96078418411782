import React from 'react';

// Función auxiliar para formatear moneda
const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(value);
};

// Función para formatear fechas
const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hour, minute] = timePart.split(":");
    const date = new Date(year, month - 1, day, hour, minute);
    return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short",
    })} ${date.getFullYear()}`;
};

const InformeVentasPDF = ({ datos }) => {
    const getFechaEmision = () => {
        const now = new Date();
        return now.toLocaleString('es-AR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const tableHeaderStyle = {
        backgroundColor: 'gray',
        color: 'white',
        padding: '8px',
        textAlign: 'left'
    };

    const tableCellStyle = {
        padding: '8px',
        borderBottom: '1px solid #ddd'
    };

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px'
    };

    const sectionTitleStyle = {
        fontSize: '18px',
        marginBottom: '10px',
        borderBottom: '1px solid #ccc',
        paddingBottom: '5px'
    };

    return (
        <div id="informe-para-pdf" style={{
            backgroundColor: 'white',
            padding: '5px',
            maxWidth: '900px',
            margin: '0 auto'
        }}>
            {/* Header con logo y datos de la empresa */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginBottom: '20px',
                borderBottom: '2px solid black',
                paddingBottom: '15px'
            }}>
                <div>
                    <h2 style={{ 
                        color: '#862f8b', 
                        fontSize: '24px', 
                        marginBottom: '5px',
                        marginTop: '0',
                        fontFamily: 'Montserrat',
                        fontWeight: 900,
                    }}>simplepass</h2>
                    <p style={{ margin: '2px 0', fontSize: '12px' }}>productores@simplesistemas.com.ar</p>
                    <p style={{ margin: '5px 0', fontSize: '22px' }}>N°</p>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <p style={{ margin: '2px 0', fontSize: '12px' }}>
                        <strong>Fecha de emisión:</strong> {getFechaEmision()}
                    </p>
                    <p style={{ margin: '2px 0', fontSize: '12px' }}>
                        <strong>Productor:</strong> {datos.nombreProductor || 'No especificado'}
                    </p>
                    <p style={{ margin: '2px 0', fontSize: '12px' }}>
                        <strong>CUIT:</strong> 30-71859773-7
                    </p>
                    <p style={{ margin: '2px 0', fontSize: '12px' }}>
                        <strong>IIBB:</strong> 30-71859773-7
                    </p>
                    <p style={{ margin: '2px 0', fontSize: '12px' }}>
                        <strong>Inicio de Actividades:</strong> 15/01/2024
                    </p>
                    <p style={{ margin: '2px 0', fontSize: '12px' }}>
                        <strong>Razón social:</strong> HERGON SOFT S.A.S.
                    </p>
                </div>
            </div>

            <h1 style={{
                fontSize: '16px',
                marginBottom: '20px'
            }}>
                Informe de ventas <br />
                <span>{datos.tituloEvento}</span> - <span>{formatDate(datos.fechaRealizazion)}</span>
            </h1>

            {/* Cards de resumen */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px'
            }}>
                <div style={{
                    width: '30%',
                    padding: '16px',
                    borderRadius: '15px',
                    backgroundColor: '#862f8b',
                    color: 'white'
                }}>
                    <h5 style={{ fontSize: '14px', marginBottom: '8px' }}>Ventas totales</h5>
                    <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{formatCurrency(datos.ventasTotales)}</p>
                </div>

                <div style={{
                    width: '30%',
                    padding: '16px',
                    borderRadius: '15px',
                    backgroundColor: '#862f8b',
                    color: 'white'
                }}>
                    <h5 style={{ fontSize: '14px', marginBottom: '8px' }}>A liquidar por ventas*</h5>
                    <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{formatCurrency(datos.netoARecibir)}</p>
                </div>

                <div style={{
                    width: '30%',
                    padding: '16px',
                    borderRadius: '15px',
                    backgroundColor: '#862f8b',
                    color: 'white'
                }}>
                    <h5 style={{ fontSize: '14px', marginBottom: '8px' }}>Comisiones de simplepass</h5>
                    <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{formatCurrency(datos.totalComiciones)}</p>
                </div>
            </div>

            

            {/* Tabla de Tickets Vendidos */}
            {datos.cantidadTicketsVendidos && datos.cantidadTicketsVendidos.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={sectionTitleStyle}>Cantidades de tickets vendidos</h2>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Tipo</th>
                                <th style={tableHeaderStyle}>Cantidad</th>
                                <th style={tableHeaderStyle}>Precio</th>
                                <th style={tableHeaderStyle}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.cantidadTicketsVendidos.map((ticket, index) => (
                                <tr key={index}>
                                    <td style={tableCellStyle}>{ticket.nombreTipoTicket}</td>
                                    <td style={tableCellStyle}>{ticket.cantidadVendida}</td>
                                    <td style={tableCellStyle}>{formatCurrency(ticket.precio)}</td>
                                    <td style={tableCellStyle}>{formatCurrency(ticket.total)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="3" style={{...tableCellStyle, textAlign: 'right', fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    Total tickets:
                                </td>
                                <td style={{...tableCellStyle, fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    {formatCurrency(datos.cantidadTicketsVendidos.reduce((acc, ticket) => acc + ticket.total, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {/* Tabla de Tickets Enviados Directamente */}
            {datos.cantidadTicketsVendidos && 
             datos.cantidadTicketsVendidos.some(ticket => ticket.cantidadEnviadaDirectamente > 0) && (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={sectionTitleStyle}>Tickets enviados directamente</h2>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Tipo</th>
                                <th style={tableHeaderStyle}>Cantidad QRs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.cantidadTicketsVendidos
                                .filter(ticket => ticket.cantidadEnviadaDirectamente > 0)
                                .map((ticket, index) => (
                                    <tr key={index}>
                                        <td style={tableCellStyle}>{ticket.nombreTipoTicket}</td>
                                        <td style={tableCellStyle}>{ticket.cantidadEnviadaDirectamente}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}

            {/* Tabla de Butacas Vendidas */}
            {datos.resumenButacasVendidas && datos.resumenButacasVendidas.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={sectionTitleStyle}>Cantidades de butacas vendidas</h2>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Sección</th>
                                <th style={tableHeaderStyle}>Fila</th>
                                <th style={tableHeaderStyle}>Cantidad</th>
                                <th style={tableHeaderStyle}>Precio</th>
                                <th style={tableHeaderStyle}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenButacasVendidas.map((butaca, index) => (
                                <tr key={index}>
                                    <td style={tableCellStyle}>{butaca.seccion}</td>
                                    <td style={tableCellStyle}>{butaca.fila}</td>
                                    <td style={tableCellStyle}>{butaca.totalVendidas}</td>
                                    <td style={tableCellStyle}>{formatCurrency(butaca.precio)}</td>
                                    <td style={tableCellStyle}>{formatCurrency(butaca.total)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="4" style={{...tableCellStyle, textAlign: 'right', fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    Total butacas:
                                </td>
                                <td style={{...tableCellStyle, fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    {formatCurrency(datos.resumenButacasVendidas.reduce((acc, butaca) => acc + butaca.total, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {/* Tabla de Mesas Vendidas */}
            {datos.resumenMesasVendidas && datos.resumenMesasVendidas.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={sectionTitleStyle}>Cantidades de mesas vendidas</h2>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Nombre</th>
                                <th style={tableHeaderStyle}>Cantidad</th>
                                <th style={tableHeaderStyle}>Precio</th>
                                <th style={tableHeaderStyle}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenMesasVendidas.map((mesa, index) => (
                                <tr key={index}>
                                    <td style={tableCellStyle}>{mesa.nombre}</td>
                                    <td style={tableCellStyle}>{mesa.totalVendidas}</td>
                                    <td style={tableCellStyle}>{formatCurrency(mesa.precio)}</td>
                                    <td style={tableCellStyle}>{formatCurrency(mesa.total)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="3" style={{...tableCellStyle, textAlign: 'right', fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    Total mesas:
                                </td>
                                <td style={{...tableCellStyle, fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    {formatCurrency(datos.resumenMesasVendidas.reduce((acc, mesa) => acc + mesa.total, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {/* Tabla de Consumiciones Vendidas */}
            {datos.resumenProductosConsumidos && datos.resumenProductosConsumidos.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={sectionTitleStyle}>Cantidades de consumiciones vendidas</h2>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Consumición</th>
                                <th style={tableHeaderStyle}>Cantidad</th>
                                <th style={tableHeaderStyle}>Precio</th>
                                <th style={tableHeaderStyle}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenProductosConsumidos.map((producto, index) => (
                                <tr key={index}>
                                    <td style={tableCellStyle}>{producto.nombreProducto}</td>
                                    <td style={tableCellStyle}>{producto.cantidad}</td>
                                    <td style={tableCellStyle}>{formatCurrency(producto.precio)}</td>
                                    <td style={tableCellStyle}>{formatCurrency(producto.cantidad * producto.precio)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="3" style={{...tableCellStyle, textAlign: 'right', fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    Total consumiciones:
                                </td>
                                <td style={{...tableCellStyle, fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    {formatCurrency(datos.resumenProductosConsumidos.reduce((acc, producto) => acc + (producto.cantidad * producto.precio), 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {/* Tabla de Ventas por RRPP */}
            {datos.ventasPorRrpp && datos.ventasPorRrpp.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={sectionTitleStyle}>Ventas por RRPP</h2>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Nombre</th>
                                <th style={tableHeaderStyle}>Cantidad Tickets</th>
                                <th style={tableHeaderStyle}>Total Vendido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.ventasPorRrpp.map((rrpp, index) => (
                                <tr key={index}>
                                    <td style={tableCellStyle}>{rrpp.nombre}</td>
                                    <td style={tableCellStyle}>{rrpp.cantidadTickets}</td>
                                    <td style={tableCellStyle}>{formatCurrency(rrpp.totalVendido)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="2" style={{...tableCellStyle, textAlign: 'right', fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    Total ventas RRPP:
                                </td>
                                <td style={{...tableCellStyle, fontWeight: 'bold', backgroundColor: 'white', color: '#862f8b', border: 'none'}}>
                                    {formatCurrency(datos.ventasPorRrpp.reduce((acc, rrpp) => acc + rrpp.totalVendido, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {/* Footer */}
            <div style={{
                marginTop: '15px',
                borderTop: '1px solid #ccc',
                paddingTop: '8px',
                fontSize: '12px',
                color: '#666',
                textAlign: 'center'
            }}>
                <p style={{ margin: '1px 0' }}>Este informe fue generado automáticamente por simplepass. No contempla devoluciones. S.E.U.O.</p>
                <p style={{ margin: '1px 0' }}>© {new Date().getFullYear()} simplepass - Todos los derechos reservados</p>
                <p style={{ margin: '1px 0' }}>www.simplepass.com.ar</p>
            </div>
        </div>
    );
};

export default InformeVentasPDF; 