import React from 'react';
import styles from "../../Pages/generar-y-enviar-qr/EnviarQrsPage.module.scss";
import { Link } from 'react-router-dom';
import { Icon } from "@iconify/react";

const TiposDeTicketsComponent = ({ tiposDeTickets, eventoId, nombreEvento }) => {
    return tiposDeTickets.map((ticket) => {
        const isUnavailable = !ticket.estaDisponible || ticket.cantidadVendida >= ticket.cantidadLimite;
        const encodedNombreEvento = encodeURIComponent(nombreEvento);
        const encodedNombreTicket = encodeURIComponent(ticket.nombre);

        const ticketStatusStyle = isUnavailable ? { 
            color: '#5c5c5c'
        } : {};
        
        return (
            <div key={ticket.id} className={`${styles.tipoTicketGeneral} ${isUnavailable ? styles.unavailableTicket : ''}`}>
                <div className={styles.detalleTipoTicket}>
                    <h4 style={ticketStatusStyle}>{ticket.nombre}</h4>
                    <h5 style={ticketStatusStyle}>
                        {isUnavailable ? "NO DISPONIBLE" : `$${ticket.precio}`}
                    </h5>
                </div>
                <div className={styles.botonGenerar}>
                    {isUnavailable ? (
                        <span className={styles.unavailableLabel}>
                            <Icon icon="material-symbols:error-outline" />
                            NO DISPONIBLE
                        </span>
                    ) : (
                        <Link to={`/generar-y-enviar-qr/${eventoId}/${ticket.id}/${encodedNombreEvento}/${encodedNombreTicket}`}>
                            Generar QRs <Icon icon="mingcute:add-fill" />
                        </Link>
                    )}
                </div>
            </div>
        );
    });
};

export default TiposDeTicketsComponent;

