import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './InformeVentasRrppPage.module.scss';
import variables from "../../Context/Variables";
import { useAuth } from '../../Context/AuthProvider';
import { Icon } from "@iconify/react";
import Alert from '../../Components/Alert/Alert';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const InformeVentasRrppPage = () => {
    const [informe, setInforme] = useState(null);
    const [loading, setLoading] = useState(true);
    const { eventoId, rrppId } = useParams();
    const { jwt } = useAuth();

    useEffect(() => {
        document.title = "Informe de ventas RRPP";
        if (jwt && eventoId && rrppId) {            
            fetchInformeVentas();
        }
    }, [eventoId, rrppId]);

    const fetchInformeVentas = async () => {
        try {
            const response = await axios.get(
                `${variables.API_BASE_URL}/api/rrpps/${eventoId}/informe-ventas/${rrppId}`,
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }
            );
            setInforme(response.data);
            setLoading(false);
        } catch (error) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudo cargar el informe de ventas.'
            });
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <LoaderSimplepass />
            </div>
        );
    }

    const totalTicketsPorTipo = Object.values(informe?.detalleVentas || {}).reduce((acc, tipo) => acc + tipo.cantidad, 0);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>
                    Informe de ventas
                    <Icon icon="solar:chart-bold" width={25} />
                </h1>
                <h3 className={styles.rrppNombre}>{informe?.rrppNombre}</h3>
                <span>Reporte detallado de ventas para {informe?.rrppNombre} en {informe?.eventoNombre}</span>
            </div>

            <div className={styles.statsContainer}>
                <div className={styles.statCard}>
                    <Icon icon="solar:dollar-linear" className={styles.statIcon} />
                    <div className={styles.statInfo}>
                        <h3>Ventas totales</h3>
                        <span className={styles.statValue}>${informe?.montoTotal.toLocaleString()}</span>
                    </div>
                </div>

                <div className={styles.statCard}>
                    <Icon icon="solar:ticket-line-duotone" className={styles.statIcon} />
                    <div className={styles.statInfo}>
                        <h3>Tickets vendidos</h3>
                        <span className={styles.statValue}>{totalTicketsPorTipo}</span>
                    </div>
                </div>
            </div>

            <div className={styles.sectionTitle}>Detalle por tipo de ticket</div>
            <div className={styles.ticketList}>
                {Object.entries(informe?.detalleVentas || {}).map(([tipo, detalle]) => (
                    <div key={tipo} className={styles.ticketCard}>
                        <div className={styles.ticketHeader}>
                            <div className={styles.ticketInfo}>
                                <Icon icon="solar:ticket-line-duotone" className={styles.ticketIcon} />
                                <h3>{tipo}</h3>
                            </div>
                            <span className={styles.cantidad}>{detalle.cantidad} tickets</span>
                        </div>
                        <div className={styles.ticketDetails}>
                            <div className={styles.detailRow}>
                                <span className={styles.label}>Precio unitario</span>
                                <span className={styles.value}>${detalle.precioUnitario.toLocaleString()}</span>
                            </div>
                            <div className={styles.detailRow}>
                                <span className={styles.label}>Subtotal</span>
                                <span className={styles.value}>${detalle.subtotal.toLocaleString()}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InformeVentasRrppPage; 