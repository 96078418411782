import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from "./GestionRrrpEventoPage.module.scss";
import Swal from 'sweetalert2';
import variables from "../../Context/Variables";
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import Alert from '../../Components/Alert/Alert';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';
import CardColaborador from '../../Components/CardColaborador/CardColaborador';

const GestionRrrpEventoPage = () => {
    const [rrppsAsignados, setRrppsAsignados] = useState([]);
    const [rrppsDisponibles, setRrppsDisponibles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(true);
    const { eventoId, nombre } = useParams();
    const navigate = useNavigate();
    const [assigningRrppIds, setAssigningRrppIds] = useState(new Set());
    const [unassigningRrppIds, setUnassigningRrppIds] = useState(new Set());
    const { jwt } = useAuth();
    const [mostrarPassword, setMostrarPassword] = useState({});

    // Método para decodificar un string de URL
    const decodeStringFromURL = (str) => {
        return decodeURIComponent(str);
    };
    useEffect(() => {
        document.title = "RRPPs del evento: " + decodeStringFromURL(nombre);
        ensureAuthenticated();
        fetchRrpps(eventoId);
    }, [eventoId, nombre]);

    const ensureAuthenticated = () => {
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);
            if (details.rol !== "PRODUCTOR") {
                setHasPermission(false);
            }
        }
    };

    const fetchRrpps = async (eventoId) => {
        try {
            const [asignadosResponse, disponiblesResponse] = await Promise.all([
                axios.get(`${variables.API_BASE_URL}/api/rrpps/${eventoId}/asignados`, {
                    headers: { Authorization: `Bearer ${jwt}` }
                }),
                axios.get(`${variables.API_BASE_URL}/api/rrpps/${eventoId}/no-asignados`, {
                    headers: { Authorization: `Bearer ${jwt}` }
                })
            ]);

            setRrppsAsignados(asignadosResponse.data);
            setRrppsDisponibles(disponiblesResponse.data);
            setLoading(false);
        } catch (error) {
            console.error('Error al cargar los RRPPs: ', error);
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: error.message || 'No se pudieron cargar los RRPPs del evento.'
            });
            setLoading(false);
        }
    };

    const handleAssign = async (rrppId) => {
        setAssigningRrppIds(prev => new Set(prev.add(rrppId)));
        try {
            await axios.post(`${variables.API_BASE_URL}/api/rrpps/${eventoId}/asignar/${rrppId}`, null, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            fetchRrpps(eventoId);
            Alert({
                tipo: 'success',
                titulo: 'Asignado',
                descripcion: 'RRPP asignado al evento exitosamente.'
            });
        } catch (error) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudo asignar el RRPP al evento.'
            });
        }
        setAssigningRrppIds(prev => {
            const newSet = new Set(prev);
            newSet.delete(rrppId);
            return newSet;
        });
    };

    const handleUnassign = async (rrppId) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, desasignar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setUnassigningRrppIds(prev => new Set(prev.add(rrppId)));
                try {
                    await axios.delete(`${variables.API_BASE_URL}/api/rrpps/${eventoId}/desasignar/${rrppId}`, {
                        headers: {
                            Authorization: `Bearer ${jwt}`
                        }
                    });
                    fetchRrpps(eventoId);
                    Alert({
                        tipo: 'success',
                        titulo: 'Desasignado',
                        descripcion: 'El RRPP ha sido desasignado del evento.'
                    });
                } catch (error) {
                    Alert({
                        tipo: 'error',
                        titulo: 'Error',
                        descripcion: 'No se pudo desasignar el RRPP del evento.'
                    });
                }
                setUnassigningRrppIds(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(rrppId);
                    return newSet;
                });
            }
        });
    };

    const togglePasswordVisibility = (rrppId) => {
        setMostrarPassword(prevState => ({
            ...prevState,
            [rrppId]: !prevState[rrppId]
        }));
    };

    const handleVerInforme = (rrppId) => {
        navigate(`/informe-ventas-rrpp/${eventoId}/${rrppId}`);
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <LoaderSimplepass />
            </div>
        );
    }

    if (!hasPermission) {
        return <NoPermissionPage />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>
                    RRPPs del evento: "{decodeStringFromURL(nombre)}"
                </h1>
                <span>Gestiona los RRPPs asignados a este evento y asigna nuevos vendedores desde tu equipo.</span>
            </div>

            <div className={styles.sectionTitle}>RRPPs Asignados</div>
            <div className={styles.staffList}>
                {rrppsAsignados.length > 0 ? (
                    rrppsAsignados.map(rrpp => (
                        <CardColaborador
                            key={rrpp.id}
                            colaborador={{
                                id: rrpp.id,
                                username: rrpp.nombre,
                                email: rrpp.email,
                                contrasena: rrpp.password
                            }}
                            onUnassign={handleUnassign}
                            isUnassigning={unassigningRrppIds.has(rrpp.id)}
                            showPassword={mostrarPassword[rrpp.id]}
                            onTogglePassword={() => togglePasswordVisibility(rrpp.id)}
                            isAssignedSection={true}
                            onVerInforme={() => handleVerInforme(rrpp.id)}
                        />
                    ))
                ) : (
                    <p className={styles.noStaff}>No hay RRPPs asignados a este evento.</p>
                )}
            </div>

            {rrppsDisponibles.length > 0 && (
                <>
                    <div className={styles.sectionTitle}>RRPPs Disponibles</div>
                    <div className={styles.staffList}>
                        {rrppsDisponibles.map(rrpp => (
                            <CardColaborador
                                key={rrpp.id}
                                colaborador={{
                                    id: rrpp.id,
                                    username: rrpp.nombre,
                                    email: rrpp.email
                                }}
                                onAssign={handleAssign}
                                isAssigning={assigningRrppIds.has(rrpp.id)}
                                isAssignedSection={false}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default GestionRrrpEventoPage;
