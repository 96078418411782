import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubmitButton from '../Buttons-loaders-inputs/SubmitButton/SubmitButton';
import PhoneInputComponent from '../PhoneInputComponent/PhoneInputComponent';
import styles from "./EnvioDeQrForm.module.scss";
import variables from "../../Context/Variables";
import Alert from "../Alert/Alert";
import { Icon } from "@iconify/react";
import { useAuth } from '../../Context/AuthProvider';

const EnvioDeQrForm = () => {
  const { eventoId, tipoDeTicketId, nombreEvento, nombreTicket } = useParams();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [envio, setEnvio] = useState("email"); // Opciones: email, whatsapp, ambas
  const [cantidad, setCantidad] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nombreCompleto, setNombreCompleto] = useState('');
  const [dni, setDni] = useState('');
  const [celular, setCelular] = useState('');
  const [metodoEnvio, setMetodoEnvio] = useState('email');
  const navigate = useNavigate();
  const { jwt } = useAuth();

  const safeDoubleDecodeURIComponent = (component) => {
    try {
      return decodeURIComponent(decodeURIComponent(component));
    } catch (error) {
      console.error('Error decoding URI component:', component, error);
      return "-";
    }
  };

  useEffect(() => {
    console.log(metodoEnvio);
  }, [metodoEnvio]);

  const decodedNombreEvento = safeDoubleDecodeURIComponent(nombreEvento);
  const decodedNombreTicket = safeDoubleDecodeURIComponent(nombreTicket);
  

  const ajustarCantidad = (cambio) => {
    setCantidad((prev) => Math.max(0, Math.min(10, prev + cambio)));
  };
  const validarNombre = (nombre) => {
    return nombre.length >= 4 && nombre.length <= 30;
  };
  const validarDNI = (dni) => {
    const regexDNI = /^\d{7,8}$/; // Cambiado a 7-8 caracteres
    return regexDNI.test(dni);
  };
  const validarCelular = (celular) => {
    const regexCelular = /^(?:\+?549?)?([0-9]{10})$/;
    return regexCelular.test(celular);
  };

  const validarEmail = (email) => {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regexEmail.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (metodoEnvio === 'email' || metodoEnvio === 'ambos') {
      if (!validarEmail(email)) {
        Alert({
          tipo: 'error',
          titulo: 'Email Inválido',
          descripcion: 'Por favor, ingresa un email válido.',
          duracion: 5000
        });
        return;
      }

      if (email !== confirmEmail) {
        Alert({
          tipo: 'warning',
          titulo: 'Oops...',
          descripcion: 'Los emails no coinciden. Por favor, verifica y trata nuevamente.',
          duracion: 5000
        });
        return;
      }
    }

    if (metodoEnvio === 'whatsapp' || metodoEnvio === 'ambos') {
      if (!validarCelular(celular)) {
        Alert({
          tipo: 'error',
          titulo: 'Celular Inválido',
          descripcion: 'El número de celular debe ser válido y contener 10 dígitos.',
          duracion: 5000
        });
        return;
      }
    }

    if (!validarDNI(dni)) {
      Alert({
        tipo: "error",
        titulo: "DNI Inválido",
        descripcion:
          "El DNI debe tener entre 7 y 8 números sin espacios ni letras.", 
        duracion: 5000,
      });
      return;
    }
    if (!validarNombre(nombreCompleto)) {
      Alert({
        tipo: "error",
        titulo: "Nombre Inválido",
        descripcion: "El nombre debe tener entre 4 y 30 caracteres.",
        duracion: 3000,
      });
      return;
    }

    setIsLoading(true);
    const userDetails =
      sessionStorage.getItem("userDetails") ||
      localStorage.getItem("userDetails");
    const token = userDetails ? JSON.parse(userDetails).jwt : null;
    try {
      const requestBody = {
        eventoId: eventoId,
        tipoDeTicketId,
        email: metodoEnvio === 'whatsapp' ? null : email,
        nombreCompleto,
        dni,
        celular: metodoEnvio === 'email' ? null : celular,
        cantidad
      };

      const response = await axios.post(`${variables.API_BASE_URL}/api/tickets/enviar-tickets`, requestBody, {
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        }
      }
      );
      if (response.status === 200) {
        Alert({
          tipo: "success",
          titulo: "¡Enviado!",
          descripcion: "Tickets enviados correctamente.",
          duracion: 5000,
        }).then(() => {
          Alert({
            tipo: 'warning',
            titulo: '¿Qué deseas hacer?',
            descripcion: '¿Ir al Panel de Control o Volver a Generar?',
            duracion: 0
          }).then((result) => {
            if (result === "Cerrar") {
              navigate("/panel-productor");
            } else {
              navigate(`/enviar-qrs/${eventoId}`);
            }
          });
        });
      } else {
        throw new Error("No se pudo enviar los tickets correctamente.");
      }
    } catch (error) {
      Alert({
        tipo: "error",
        titulo: "Error",
        descripcion:
          "Error al enviar los tickets: " +
          (error.response?.data || error.message),
        duracion: 4000,
      });
      console.error("Error al enviar tickets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMetodoEnvioChange = (e) => {
    const selectedMetodo = e.target.value;
    setMetodoEnvio(selectedMetodo);

    if (selectedMetodo === 'email') {
      setCelular('');
    } else if (selectedMetodo === 'whatsapp') {
      setEmail('');
      setConfirmEmail('');
    }
  };

  return (
    <div className={styles.envioQrForm}>
      <h2 className={styles.titulo}>Configuración y envío de QRs</h2>
      <h3 className={styles.subtitulo}>
        {nombreEvento || "Nombre del Evento"}
      </h3>
      <form onSubmit={handleSubmit} className={styles.formulario}>
        <div className={styles.inputGroup}>
          <div className={styles.radioGroup}>
            <label className={styles.label}>Método de Envío:</label>
            <div>
              <label>
                <input
                  type="radio"
                  value="email"
                  checked={metodoEnvio === 'email'}
                  onChange={handleMetodoEnvioChange}
                />
                Enviar por Email
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="whatsapp"
                  checked={metodoEnvio === 'whatsapp'}
                  onChange={handleMetodoEnvioChange}
                />
                Enviar por WhatsApp
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="ambos"
                  checked={metodoEnvio === 'ambos'}
                  onChange={handleMetodoEnvioChange}
                />
                Enviar por Email y WhatsApp
              </label>
            </div>
          </div>
        </div>

        {/* Campos de Email */}
        {(metodoEnvio === 'email' || metodoEnvio === 'ambos') && (
          <>
            <div className={styles.inputGroup}>
              <label htmlFor="email" className={styles.label}>Email:</label>
              <input
                type="email"
                id="email"
                placeholder='Donde se recibirán los tickets'
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                required
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="confirmEmail" className={styles.label}>Confirmar Email:</label>
              <input
                type="email"
                id="confirmEmail"
                placeholder='Repetir el email por seguridad'
                className={styles.input}
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value.trim())}
                required
                onPaste={(e) => e.preventDefault()}
              />
            </div>
          </>
        )}

        {/* Campos comunes */}
        <div className={styles.inputGroup}>
          <label htmlFor="nombreCompleto" className={styles.label}>Nombre Completo:</label>
          <input
            type="text"
            id="nombreCompleto"
            placeholder='Nombre de quien utilizará los tickets'
            className={styles.input}
            value={nombreCompleto}
            onChange={(e) => setNombreCompleto(e.target.value)}
            required
          />
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="dni">DNI</label>
          <input
            type="text"
            id="dni"
            placeholder='DNI de quien utilizará los tickets'
            className={styles.input}
            value={dni}
            onChange={(e) => setDni(e.target.value.slice(0, 8))} // Limitar a 8 caracteres
            required
          />
        </div>

        {/* Campo de Celular */}
        {(metodoEnvio === 'whatsapp' || metodoEnvio === 'ambos') && (
          <div className={styles.inputGroup}>
            <label htmlFor="celular" className={styles.label}>Celular:</label>
            <PhoneInputComponent
              value={celular}
              onChange={(value) => setCelular(value.trim())}
              required
            />
          </div>
        )}

        <div className={styles.inputGroup}>
          <label htmlFor="cantidad" className={styles.label}>Cantidad (máximo 10):</label>
          <div className={styles.cantidadControles}>
            <button 
              type="button" 
              className={styles.boton} 
              onClick={() => ajustarCantidad(-1)} 
              disabled={cantidad <= 0}
            >
              <Icon icon="fluent:subtract-16-regular" />
            </button>
            <input
              type="number"
              id="cantidad"
              className={styles.inputCantidad}
              value={cantidad}
              readOnly
            />
            <button 
              type="button" 
              className={styles.boton} 
              onClick={() => ajustarCantidad(1)} 
              disabled={cantidad >= 10}
            >
              <Icon icon="mingcute:add-fill" width={16} />
            </button>
          </div>
        </div>

        <SubmitButton
          isDisabled={
            (metodoEnvio === 'email' && (!email || !confirmEmail || email !== confirmEmail)) ||
            (metodoEnvio === 'whatsapp' && !validarCelular(celular)) ||
            (metodoEnvio === 'ambos' && (!email || !confirmEmail || email !== confirmEmail || !validarCelular(celular))) ||
            !validarNombre(nombreCompleto) ||
            !validarDNI(dni) ||
            cantidad <= 0 ||
            cantidad > 10 ||
            isLoading
          }
          color="#99419d"
          isLoading={isLoading}
        >
          Enviar QR
        </SubmitButton>
      </form>
    </div>
  );
};

export default EnvioDeQrForm;