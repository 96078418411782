import React, { useState, useEffect, useRef, useCallback } from "react";
import WhatsAppPop from "../../../Components/Buttons-loaders-inputs/WhatsAppPop/WhatsAppPop";
import styles from "./EventoDetalles.module.scss";
import AutenticadorUnificado from "../../../Components/AutenticadorUnificado/AutenticadorUnificado";
import axios from "axios";
import variables from "../../../Context/Variables";
import MapComponent from "../../../Components/Mapa-GoogleMap/Map";
import EventoDetalles from "../../../Components/EventoDetalles/EventoDetalles";
import ListaTickets from "../../../Components/ListaTickets/ListaTickets";
import MenuConsumiciones from "../../../Components/MenuConsumiciones/MenuConsumiciones";
import ListaMesas from "../../../Components/ListaMesas/ListaMesas";
import MercadoPagoButton from "../../../Components/Mercado Pago/MercadoPagoButton/MercadoPagoButton";
import Alert from "../../../Components/Alert/Alert";
import ResumenCarrito from "../../../Components/ResumenCarrito/ResumenCarrito";
import PaymentFormEmbedded from "../../../Components/Mobbex/PaymentFormEmbedded";
import SeccionButacas from "../../../MapasButacas/SeccionButacas/SeccionButacas";
import TimerAlert from "../../../Components/TimerAlert/TimerAlert";
import { useAuth } from "../../../Context/AuthProvider";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: "symbol",
  }).format(value).replace(",", ".");
};

function formatearFecha(fechaCompleta) {
  const partesFechaHora = fechaCompleta.split(" ");
  const partesFecha = partesFechaHora[0].split("-");
  return `${partesFecha[2]}/${partesFecha[1]}/${partesFecha[0]}`;
}

const EventoCompra = ({ evento, rrpp }) => {
  const [carrito, setCarrito] = useState({
    tickets: {},
    butacas: {},
    productos: {},
    mesas: {}
  });

  const isCarritoVacio = () => {
    const tieneTickets = Object.keys(carrito.tickets).length > 0;
    const tieneButacas = Object.keys(carrito.butacas).length > 0;
    const tieneProductos = Object.keys(carrito.productos).length > 0;
    const tieneMesas = Object.keys(carrito.mesas).length > 0;

    return !tieneTickets && !tieneButacas && !tieneProductos && !tieneMesas;
  };


  const [total, setTotal] = useState(0);
  const [cargoPorServicio, setCargoPorServicio] = useState(0);
  const [precioDesde, setPrecioDesde] = useState("Cargando...");
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [tiposDeTicketsDisponibles, setTiposDeTicketsDisponibles] = useState([]);
  const [butacasDisponibles, setButacasDisponibles] = useState([]);
  const mesasDisponiblesRef = useRef([]);
  const [errorCompra, setErrorCompra] = useState("");
  const [mostrarMenu, setMostrarMenu] = useState(true);
  const [ubicacion, setUbicacion] = useState("");
  const [cargandoCompra, setCargandoCompra] = useState(false);
  const [mostrarTodos, setMostrarTodos] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [precioMesaDesde, setPrecioMesaDesde] = useState("");
  const [descripcionMesa, setDescripcionMesa] = useState("");
  const [usuarioNecesitaAutenticacion, setUsuarioNecesitaAutenticacion] = useState(false);
  const [debeVerificarAutenticacion, setDebeVerificarAutenticacion] = useState(false);
  const [mostrarOpcionesDePago, setMostrarOpcionesDePago] = useState(false);
  const [modalAbierto, setModalAbierto] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const { jwt } = useAuth();

  const entradas = evento.tiposDeTickets
    .filter(
      (tipoTicket) =>
        tipoTicket.fila === null &&
        tipoTicket.numeroAsiento === null &&
        tipoTicket.seccion === null &&
        (tipoTicket.esMesa === null || tipoTicket.esMesa === false)
    )
    .sort((a, b) => a.nombre.localeCompare(b.nombre));

  useEffect(() => {
    if (evento) {
      setProductosDisponibles(evento.productos?.filter(p => p.habilitadoParaVenta !== false) || []);
      setTiposDeTicketsDisponibles(evento.tiposDeTickets || []);

      const butacas = evento.tiposDeTickets.filter(
        (ticket) => ticket.fila && ticket.seccion && ticket.numeroAsiento
      );
      setButacasDisponibles(butacas);

      actualizarPrecioDesde(evento.tiposDeTickets || []);
      if (evento.productos.length === 0 || evento.productos === null) {
        setMostrarMenu(false);
      }

      const direccionLimpia = obtenerDireccionLimpia(evento);
      setUbicacion(direccionLimpia);
      setearMesas(evento);
    }
  }, [evento]);

  const handleModalAbierto = (estado) => {
    setModalAbierto(estado);
  };

  useEffect(() => {
    loadMobbexSDK();
  }, []);

  const loadMobbexSDK = () => {
    if (!window.MobbexEmbed) {
      if (!window.mobbexSDKLoading) {
        window.mobbexSDKLoading = true;
        const script = document.createElement('script');
        script.src = 'https://res.mobbex.com/js/embed/mobbex.embed@1.0.23.js';
        script.async = true;
        script.onload = () => {
          window.mobbexSDKLoading = false;
        };
        document.body.appendChild(script);
      }
    }
  };

  function obtenerDireccionLimpia(evento) {
    if (!evento) return "";

    let direccionFinal = "";

    if (evento.googleMapUrl) {
      let partes = evento.googleMapUrl.split("/");
      let direccion = partes[0];
      direccionFinal = direccion
        .trim()
        .replace(/^,|,$/g, "")
        .replace(/,(\s*,)+/g, ",")
        .replace(/,\s+/g, ", ");
    } else if (evento.ubicacion) {
      direccionFinal = evento.ubicacion;
    }

    return direccionFinal;
  }

  useEffect(() => {
    let intervalId;

    if (debeVerificarAutenticacion) {
      intervalId = setInterval(() => {
        const userDetails =
          sessionStorage.getItem("userDetails") ||
          localStorage.getItem("userDetails");
        if (userDetails) {
          setUsuarioNecesitaAutenticacion(false);
          setDebeVerificarAutenticacion(false);
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [debeVerificarAutenticacion]);

  useEffect(() => {
    calcularTotalYComision();
  }, [carrito]);

  const setearMesas = (evento) => {
    const mesas = evento.tiposDeTickets.filter((ticket) => ticket.esMesa && ticket.estaDisponible);
    mesasDisponiblesRef.current = mesas;

    if (mesas.length > 0) {
      const precios = mesas.map(mesa => mesa.precio);
      const precioMinimo = Math.min(...precios);
      setPrecioMesaDesde(formatCurrency(precioMinimo));
    } else {
      setPrecioMesaDesde("No disponible");
    }

    if (evento.descripcionMesas !== null) {
      setDescripcionMesa(evento.descripcionMesas);
    }
  };

  const ajustarCantidadTicket = (idTicket, cambio) => {
    setCarrito((prev) => {
      const nuevaCantidad = Math.max((prev.tickets[idTicket]?.cantidad || 0) + cambio, 0);
      return {
        ...prev,
        tickets: {
          ...prev.tickets,
          [idTicket]: {
            ...prev.tickets[idTicket],
            cantidad: nuevaCantidad,
          },
        },
      };
    });
  };

  const handleMesaChange = (selectedOptions) => {
    const selectedMesas = selectedOptions.map(option => option.value);
    const mesasConDetalles = selectedOptions.map(option => mesasDisponiblesRef.current.find(mesa => mesa.id === option.value));

    setCarrito(prevCarrito => {
      const updatedMesas = { ...prevCarrito.mesas };

      mesasDisponiblesRef.current.forEach(mesa => {
        if (selectedMesas.includes(mesa.id)) {
          updatedMesas[mesa.id] = { ...mesa, cantidad: 1 };
        } else {
          delete updatedMesas[mesa.id];
        }
      });

      return {
        ...prevCarrito,
        mesas: updatedMesas
      };
    });

    calcularTotalYComision();
  };

  // Dentro de EventoCompra.jsx
  const handleAsientosSeleccionados = useCallback((selectedSeats) => {
    const updatedButacas = selectedSeats.reduce((acc, seat) => {
      acc[seat.id] = { ...seat, cantidad: 1 };
      return acc;
    }, {});

    setCarrito((prevCarrito) => ({
      ...prevCarrito,
      butacas: updatedButacas,
    }));
  }, [setCarrito]);

  const calcularTotalYComision = useCallback(() => {
    let subtotalTickets = 0;
    let subtotalProductos = 0;
    let subtotalButacas = 0;
    let subtotalMesas = 0;

    Object.entries(carrito.tickets).forEach(([id, { cantidad }]) => {
      const precioTicket = tiposDeTicketsDisponibles.find((t) => String(t.id) === String(id))?.precio || 0;
      subtotalTickets += precioTicket * cantidad;
    });

    Object.entries(carrito.productos).forEach(([id, { cantidad }]) => {
      const precioProducto = productosDisponibles.find((p) => p.id === Number(id))?.precio || 0;
      subtotalProductos += precioProducto * cantidad;
    });

    Object.entries(carrito.butacas).forEach(([id, butaca]) => {
      subtotalButacas += butaca.precio * butaca.cantidad;
    });

    Object.entries(carrito.mesas).forEach(([id, mesa]) => {
      subtotalMesas += mesa.precio * mesa.cantidad;
    });

    const subtotalNoConsumiciones = subtotalTickets + subtotalButacas + subtotalMesas;
    const subtotalConsumiciones = subtotalProductos;
    const comisionConsumiciones = subtotalConsumiciones * (evento.cargoUsuario - evento.descCargoConsumiciones);
    const comisionNoConsumiciones = subtotalNoConsumiciones * evento.cargoUsuario;
    const comision = comisionNoConsumiciones + comisionConsumiciones;
    const subtotal = subtotalNoConsumiciones + subtotalConsumiciones;

    setTotal(subtotal + comision);
    setCargoPorServicio(comision);
  }, [carrito, tiposDeTicketsDisponibles, productosDisponibles, evento.cargoUsuario]);

  const actualizarPrecioDesde = (tiposDeTickets) => {
    if (tiposDeTickets.length > 0) {
      const precios = tiposDeTickets.map((tipo) => tipo.precio);
      const precioMinimo = Math.min(...precios);
      setPrecioDesde(formatCurrency(precioMinimo));
    } else {
      setPrecioDesde("No disponible");
    }
  };

  const actualizarCarritoProductos = (idProducto, cambio) => {
    setCarrito((prevCarrito) => {
      const nuevaCantidad = Math.max((prevCarrito.productos[idProducto]?.cantidad || 0) + cambio, 0);

      const productosActualizados = {
        ...prevCarrito.productos,
        [idProducto]: {
          ...prevCarrito.productos[idProducto],
          cantidad: nuevaCantidad > 0 ? nuevaCantidad : undefined,
        },
      };

      if (nuevaCantidad <= 0) {
        delete productosActualizados[idProducto];
      }

      return { ...prevCarrito, productos: productosActualizados };
    });
  };

  const procesarCompra = async () => {
    const userDetails =
      sessionStorage.getItem("userDetails") ||
      localStorage.getItem("userDetails");
    if (!userDetails) {
      setUsuarioNecesitaAutenticacion(true);
      setDebeVerificarAutenticacion(true);
      return;
    }

    const details = JSON.parse(userDetails);
    const userRole = details.rol;

    if (userRole !== 'USER') {
      Alert({
        tipo: 'error',
        titulo: 'Acción no permitida',
        descripcion: `Su tipo de cuenta es de ${userRole === 'PRODUCTOR' ? 'Productor' : 'Staff'}`,
        duracion: 4000
      });
      return;
    }


    if (!userDetails) {
      setUsuarioNecesitaAutenticacion(true);
    } else {
      setCargandoCompra(true);
      realizarCompra();
      setIsTimerActive(true);
    }
  };

  function obtenerCarritoDtoCompleto(carrito, evento) {
    const carritoDto = {
      eventoId: evento.id.toString(),
      carritoProductos: {},
      carritoTickets: {
        tickets: {},
        butacas: {},
      },
      rrppId: rrpp ? rrpp.id : null,
    };

    Object.keys(carrito.productos).forEach((idProducto) => {
      const cantidad = carrito.productos[idProducto].cantidad;
      if (cantidad > 0) {
        carritoDto.carritoProductos[idProducto] = cantidad;
      }
    });

    Object.keys(carrito.tickets).forEach((idTicket) => {
      const cantidad = carrito.tickets[idTicket].cantidad;
      if (cantidad > 0) {
        carritoDto.carritoTickets.tickets[idTicket] = cantidad;
      }
    });

    Object.keys(carrito.butacas).forEach((idButaca) => {
      const butaca = carrito.butacas[idButaca];
      carritoDto.carritoTickets.butacas[idButaca] = {
        nombre: butaca.nombre,
        precio: butaca.precio,
        seccion: butaca.seccion,
        fila: butaca.fila,
        numeroAsiento: butaca.numeroAsiento,
      };
    });

    Object.keys(carrito.mesas).forEach((idMesa) => {
      const mesa = carrito.mesas[idMesa];
      carritoDto.carritoTickets.tickets[idMesa] = mesa.cantidad;
    });

    return carritoDto;
  }

  async function realizarCompra() {
    setCargandoCompra(true);
    setErrorCompra("");

    try {

      const carritoCompletoDto = obtenerCarritoDtoCompleto(carrito, evento);

      const response = await axios.post(
        variables.API_BASE_URL + "/api/mp/crearYRedireccionar",
        carritoCompletoDto,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      const data = response.data;

      window.location.href = data.init_point;
    } catch (error) {
      console.error("Error durante la compra:", error);
      setErrorCompra(error.message || "Ha ocurrido un error desconocido.");
    } finally {
      setCargandoCompra(false);
    }
  }

  // Agrega esta función para manejar cuando se acaba el tiempo
  const handleTimeUp = () => {
    setIsTimerActive(false);
    window.location.reload();
  };

  if (!evento) {
    return <p>No se encontraron detalles para este evento.</p>;
  }

  return (
    <section className={styles.bodyEventoDetalles}>
      <WhatsAppPop />
      <div className={styles.eventPortada}>
        <EventoDetalles evento={evento} ubicacion={ubicacion} precioDesde={precioDesde} formatearFecha={formatearFecha} />

        <section className={styles.sectionComprarTickets}>
          <div className={styles.titleContainer}>
            <div className={styles.titleLine}></div>
            <div className={styles.titleText}>Comprá tus tickets</div>
            <div className={styles.titleLine}></div>
          </div>

          {entradas.length > 0 ? (
            <div className={styles.tiposDeticketCont}>
              <div className={styles.encabezadoTickets}>
                <div>
                  <h4>Entradas</h4>
                </div>
              </div>
              <ListaTickets evento={evento} carrito={carrito} ajustarCantidadTicket={ajustarCantidadTicket} formatCurrency={formatCurrency} />
            </div>
          ) : null}

          {(butacasDisponibles.length > 0 && evento.habilitadoParaVenderButacas) ? (
            <SeccionButacas
              butacas={butacasDisponibles}
              evento={evento}
              onUpdateSelectedSeats={handleAsientosSeleccionados}
            />
          ) : null}

          {evento.habilitadoParaVenderConsumisiones && productosDisponibles.length > 0 ?
            <div id="menu" className={styles.menu} style={{ display: mostrarMenu ? "block" : "none" }}>
              <div className={`${styles.detalleTipoTicket} ${styles.contInfoConsumiciones}`}>
                <h4 className={styles.tituloMenu}>
                  Consumisiones
                </h4>
                <p>Encargate solo de disfrutar, ahorra filas innecesarias y pasá a retirar directamente con tu QR.</p>
              </div>
              <MenuConsumiciones productosDisponibles={productosDisponibles} mostrarTodos={mostrarTodos} setMostrarTodos={setMostrarTodos} carrito={carrito} actualizarCarritoProductos={actualizarCarritoProductos} formatCurrency={formatCurrency} />
            </div>
            :
            null
          }
          <ListaMesas evento={evento} mesasDisponiblesRef={mesasDisponiblesRef} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} descripcionMesa={descripcionMesa} precioMesaDesde={precioMesaDesde} handleMesaChange={handleMesaChange} formatCurrency={formatCurrency} />

          {rrpp && (
            <section className={styles.avisoRrpp} style={{ backgroundColor: "#47BD8F" }}>
              <span>Comprando a través del link de:</span>
              <p className={styles.contNombreRrrpp}>{rrpp.nombre}</p>
            </section>
          )}

          <ResumenCarrito total={total} cargoPorServicio={cargoPorServicio} formatCurrency={formatCurrency} />
        </section>

        <div id="avisoAutenticacion" className={`${styles.avisoAutenticacion} ${usuarioNecesitaAutenticacion ? styles.avisoAutenticacionVisible : ''}`}>
          <AutenticadorUnificado titulo="Ingresa tu email" rol="USER" />
        </div>

        <section className={styles.seccionBotonesDePago}>
          {errorCompra && (
            <div className={styles.mensajeError}>{errorCompra}</div>
          )}

          {!mostrarOpcionesDePago && !usuarioNecesitaAutenticacion && (
            <button
              disabled={cargandoCompra || isCarritoVacio()}
              onClick={procesarCompra}
              className={styles.btnIniciar}
            >
              {cargandoCompra ? (
                <div className={styles.loader}></div>
              ) : (
                "Comprar"
              )}
            </button>
          )}
        </section>
        {mostrarOpcionesDePago ?
          <div className={`${styles.mostrarOpcionesDePagoCont} ${mostrarOpcionesDePago ? styles.visible : ''} ${modalAbierto ? styles['modal-abierto'] : ''}`}>
            <h2 className={styles.pregunta}>¿Con qué deseas abonar?</h2>
            <PaymentFormEmbedded
              carritoCompraDto={obtenerCarritoDtoCompleto(carrito, evento)}
              jwt={jwt}
              handleModalAbierto={handleModalAbierto}
            />
            <MercadoPagoButton
              carritoCompraDto={obtenerCarritoDtoCompleto(carrito, evento)}
              evento={evento}
              setCargandoCompra={setCargandoCompra}
              setErrorCompra={setErrorCompra}
              setUsuarioNecesitaAutenticacion={setUsuarioNecesitaAutenticacion}
            />
          </div>
          : null}
        <MapComponent data={evento.googleMapUrl} />
      </div>
      <TimerAlert isActive={isTimerActive} onTimeUp={handleTimeUp} />
    </section>
  );
};

export default EventoCompra;