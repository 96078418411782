import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Icon } from "@iconify/react";
import Alert from '../../Components/Alert/Alert';
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import styles from "./CrearMenu.module.scss";
import variables from "../../Context/Variables";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

function CrearMenuPage() {
    const [productos, setProductos] = useState([]);
    const [productosExistentes, setProductosExistentes] = useState([]);
    const [loading, setLoading] = useState(false);
    const { eventoId } = useParams(); 
    const navigate = useNavigate();
    const [productosModificados, setProductosModificados] = useState({});
    const [nombresProductos, setNombresProductos] = useState({});

    useEffect(() => {
        document.title = "Crear menú";
        window.scrollTo(0, 0);
        cargarProductosExistentes();
    }, []);

    const cargarProductosExistentes = async () => {
        try {
            const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails'));
            const response = await fetch(`${variables.API_BASE_URL}/api/menu/productos-evento/${eventoId}`, {
                headers: {
                    'Authorization': `Bearer ${userDetails.jwt}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setProductosExistentes(data);
                const nombresIniciales = {};
                data.forEach(producto => {
                    nombresIniciales[producto.id] = producto.titulo;
                });
                setNombresProductos(nombresIniciales);
                setProductosModificados({});
            }
        } catch (error) {
            console.error('Error al cargar productos:', error);
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudieron cargar los productos existentes',
                duracion: 3000
            });
        }
    };

    const agregarProducto = useCallback(() => {
        if (productos.length >= 25) return;
        setProductos(current => [...current, { id: current.length + 1, titulo: "", precio: null, imagen: null }]);
    }, [productos]);
    
    const handleInputChange = (index, field, event) => {
        console.log(event); // Verificar que el evento está definido
        if (field === 'imagen') {
            const files = event.target.files;
            console.log(files); // Verificar que files está definido
            if (files && files.length > 0) {
                const file = files[0];
                console.log(file); // Verificar que file está definido
                const maxFileSize = 2 * 1024 * 1024; // 2MB
                if (file.size > maxFileSize) {
                    Alert({
                        tipo: 'error',
                        titulo: 'Error',
                        descripcion: 'El archivo supera el tamaño máximo de 2MB.',
                        duracion: 3000
                    });
                    return;
                }
                setProductos(current =>
                    current.map((producto, i) =>
                        i === index ? { ...producto, [field]: file } : producto
                    )
                );
            }
        } else {
            console.log(event.target.value); 
            setProductos(current =>
                current.map((producto, i) =>
                    i === index ? { ...producto, [field]: event.target.value } : producto
                )
            );
        }
    };
    
    const handleNombreChange = (productoId, nuevoNombre) => {
        setNombresProductos(prev => ({
            ...prev,
            [productoId]: nuevoNombre
        }));
        
        const productoOriginal = productosExistentes.find(p => p.id === productoId);
        setProductosModificados(prev => ({
            ...prev,
            [productoId]: nuevoNombre !== productoOriginal.titulo
        }));
    };

    const actualizarTituloProducto = async (productoId) => {
        try {
            const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails'));
            const response = await fetch(`${variables.API_BASE_URL}/api/menu/actualizarProducto/${productoId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${userDetails.jwt}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `nuevoTitulo=${encodeURIComponent(nombresProductos[productoId])}`
            });

            if (response.ok) {
                toast.success('¡Nombre actualizado correctamente!', {
                    position: "top-right",
                    autoClose: 3000,
                });
                setProductosModificados(prev => ({
                    ...prev,
                    [productoId]: false
                }));
                cargarProductosExistentes();
            } else {
                throw new Error('Error al actualizar el producto');
            }
        } catch (error) {
            toast.error('No se pudo actualizar el nombre del producto', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const actualizarImagenProducto = async (productoId, imagen) => {
        try {
            const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails'));
            const formData = new FormData();
            formData.append('imagen', imagen);

            const response = await fetch(`${variables.API_BASE_URL}/api/menu/actualizarImagenProducto/${productoId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${userDetails.jwt}`
                },
                body: formData
            });

            if (response.ok) {
                toast.success('¡Imagen actualizada correctamente!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                cargarProductosExistentes();
            } else {
                throw new Error('Error al actualizar la imagen');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('No se pudo actualizar la imagen del producto', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const validarFormulario = () => {

        // Ejemplo simple de validación
        for (let producto of productos) {
            if (!producto.titulo || producto.precio <= 0) {
                Alert({
                    tipo: 'error',
                    titulo: 'Error',
                    descripcion: 'Todos los campos deben ser llenados correctamente',
                    duracion: 3000
                });
                return false;
            }
        }

        return true;
    };

    const enviarMenu = (event) => {
        event.preventDefault();
        if (!validarFormulario()) return;
        
        // Verificar si no hay productos nuevos
        if (productos.length === 0) {
            Alert({
                tipo: 'warning',
                titulo: 'Atención',
                descripcion: 'No ha agregado nuevos productos al menú',
                duracion: 3000
            });
            return;
        }

        setLoading(true);
    
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'Debe estar logueado para realizar esta acción.',
                duracion: 3000
            });
            setLoading(false);
            return;
        }
        const details = JSON.parse(userDetails);
        const jwt = details.jwt;
    
        const formData = new FormData();
        formData.append('idEvento', eventoId);
        
        // Verificar si existe un menú en los productos existentes
        const menuExistente = productosExistentes.length > 0 ? productosExistentes[0].menu : null;
        if (menuExistente && menuExistente.id) {
            console.log('Menu ID existente:', menuExistente.id); // Para debugging
            formData.append('menuId', menuExistente.id);
        }

        productos.forEach((producto, index) => {
            formData.append('titulos', producto.titulo);
            formData.append('precios', producto.precio);
            if (producto.imagen && producto.imagen instanceof File) {
                formData.append('imagenes', producto.imagen, producto.imagen.name);
            } else {
                // Si no hay imagen nueva, enviar una imagen por defecto o null
                formData.append('imagenes', new File([], 'empty.jpg', { type: 'image/jpeg' }));
            }
        });

        console.log('FormData entries:'); // Para debugging
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        fetch(`${variables.API_BASE_URL}/api/menu/crear`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${jwt}` },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if(response.status === 413) {
                    throw new Error('El tamaño del archivo excede el máximo permitido.');
                }
                return response.text().then(text => { throw new Error(text || 'Algo salió mal en el servidor'); });
            }
            return response.json();
        })
        .then(async data => {
            await Alert({
                tipo: 'success',
                titulo: 'Éxito',
                descripcion: 'Menú creado con éxito',
                duracion: 3000
            });
            // Recargar los productos en lugar de navegar
            cargarProductosExistentes();
            // Limpiar el formulario
            setProductos([{ id: 1 }]);
            setLoading(false);
        })
        .catch(async error => {
            let errorMessage = 'Error desconocido';
            try {
                const errorData = JSON.parse(error.message);
                errorMessage = errorData.message || 'Error desconocido';
            } catch (e) {
                errorMessage = error.message;
            }
            await Alert({
                tipo: 'error',
                titulo: 'Error al crear el menú',
                descripcion: errorMessage,
                duracion: 5000
            });
        })
        .finally(() => {
            setLoading(false);
        });
    };
    
    const eliminarProducto = (index) => {
        setProductos(current => current.filter((_, i) => i !== index));
    };

    const eliminarProductoExistente = async (productoId) => {
        try {
            // Mostrar confirmación antes de eliminar
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esta acción!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#99419d',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails'));
                const response = await fetch(`${variables.API_BASE_URL}/api/menu/eliminarProductoDelMenu/${eventoId}/${productoId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${userDetails.jwt}`
                    }
                });

                if (response.ok) {
                    toast.success('Producto eliminado correctamente', {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    cargarProductosExistentes();
                } else {
                    throw new Error('Error al eliminar el producto');
                }
            }
        } catch (error) {
            toast.error('No se pudo eliminar el producto', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const toggleProductoHabilitado = async (productoId) => {
        try {
            const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails'));
            const response = await fetch(`${variables.API_BASE_URL}/api/menu/toggleProducto/${productoId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${userDetails.jwt}`
                }
            });

            if (response.ok) {
                // Encontrar el producto actual para saber su nuevo estado
                const producto = productosExistentes.find(p => p.id === productoId);
                const nuevoEstado = !producto.habilitado;
                
                toast.success(nuevoEstado ? 'Producto habilitado' : 'Producto deshabilitado', {
                    position: "top-right",
                    autoClose: 3000,
                });
                await cargarProductosExistentes();
            } else {
                throw new Error('Error al actualizar el producto');
            }
        } catch (error) {
            toast.error('No se pudo actualizar el estado del producto', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const handleDisabledButtonClick = () => {
        Swal.fire({
            icon: 'error',
            title: 'Acción no permitida',
            text: 'No se puede eliminar un producto que registra ventas',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        });
    };

    return (
        <div className={styles.body}>
            <ToastContainer />
            <div className={styles.crearMenuContainer}>
                <h2>Crea tu menú virtual</h2>
                <div className={styles.fondoDescripcion}>
                    <p>Agrega hasta 25 productos a tu menú. Proporciona detalles como el nombre, precio y una imagen para cada producto.</p>
                </div>

                {productosExistentes.length > 0 && (
                    <div className={styles.productosExistentes}>
                        <h3>Productos existentes en el menú</h3>
                        <div className={styles.listaProductos}>
                            {productosExistentes.map((producto) => (
                                <div key={producto.id} className={styles.productoExistente}>
                                    <div className={styles.productImage}>
                                        <img 
                                            src={producto.imagenUrl || "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Menus/food.webp"} 
                                            alt={producto.titulo}
                                        />
                                        <button 
                                            type="button"
                                            className={styles.editImageBtn}
                                            onClick={() => document.getElementById(`fileInput-existente-${producto.id}`).click()}
                                        >
                                            <Icon icon="uil:edit" width={16} />
                                        </button>
                                        <input
                                            id={`fileInput-existente-${producto.id}`}
                                            type="file"
                                            accept="image/png, image/jpeg, image/webp"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    actualizarImagenProducto(producto.id, e.target.files[0]);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={styles.detallesProducto}>
                                        <div className={styles.nombreProducto}>
                                            <input
                                                type="text"
                                                value={nombresProductos[producto.id] || ''}
                                                onChange={(e) => handleNombreChange(producto.id, e.target.value)}
                                                maxLength="20"
                                                className={styles.inputNombre}
                                            />
                                            {productosModificados[producto.id] && (
                                                <button
                                                    onClick={() => actualizarTituloProducto(producto.id)}
                                                    className={styles.guardarBtn}
                                                >
                                                    <Icon icon="material-symbols:save" />
                                                    Guardar
                                                </button>
                                            )}
                                        </div>
                                        <p>${producto.precio}</p>
                                    </div>
                                    <div className={styles.productControls}>
                                        <div className={styles.habilitarControl}>
                                            <span>Habilitar</span>
                                            <div className={styles.checkboxApple}>
                                                <input
                                                    className={styles.yep}
                                                    id={`checkApple-${producto.id}`}
                                                    type="checkbox"
                                                    checked={producto.habilitado}
                                                    onChange={() => toggleProductoHabilitado(producto.id)}
                                                />
                                                <label 
                                                    className={styles.labelToggle} 
                                                    htmlFor={`checkApple-${producto.id}`}
                                                ></label>
                                            </div>
                                        </div>
                                        <button 
                                            type="button"
                                            className={`${styles.deleteBtn} ${producto.cantidadVendida > 0 ? styles.disabled : ''}`}
                                            onClick={() => producto.cantidadVendida > 0 ? handleDisabledButtonClick() : eliminarProductoExistente(producto.id)}
                                            title={producto.cantidadVendida > 0 ? "No se puede eliminar un producto que registra ventas" : ""}
                                        >
                                            <Icon icon="solar:trash-bin-trash-bold" width={16} />
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <form onSubmit={enviarMenu}>
                    <div className={styles.productos}>
                        {productos.map((producto, index) => (
                            <div key={index} className={styles.producto}>
                                <button 
                                    type="button"
                                    className={styles.deleteBtn}
                                    onClick={() => eliminarProducto(index)}
                                >
                                    <Icon icon="solar:trash-bin-trash-bold" width={16} />
                                    Eliminar
                                </button>
                                
                                <div className={styles.productImage}>
                                    <img 
                                        src={producto.imagen ? URL.createObjectURL(producto.imagen) : "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Menus/food.webp"} 
                                        alt="Vista previa"
                                    />
                                    <Icon
                                        icon="uil:edit"
                                        width={35}
                                        className={styles.editIcon}
                                        onClick={() => document.getElementById(`fileInput-${index}`).click()}
                                    />
                                    <input
                                        id={`fileInput-${index}`}
                                        type="file"
                                        accept="image/png, image/jpeg, image/webp"
                                        style={{ display: 'none' }}
                                        onChange={e => handleInputChange(index, 'imagen', e)}
                                    />
                                </div>

                                <label>Título:</label>
                                <input
                                    type="text"
                                    placeholder="Título del Producto"
                                    maxLength="20"
                                    value={producto.titulo || ''}
                                    onChange={e => handleInputChange(index, 'titulo', e)}
                                    className={styles.inputProducto}
                                />

                                <label>Precio:</label>
                                <input
                                    type="number"
                                    placeholder="Precio sin $"
                                    value={producto.precio || ''}
                                    onChange={e => handleInputChange(index, 'precio', e)}
                                    className={styles.inputProducto}
                                />
                            </div>
                        ))}

                        <button 
                            type="button" 
                            onClick={agregarProducto} 
                            disabled={productos.length >= 25} 
                            className={styles.agregarBtn}
                        >
                            <Icon icon="solar:add-circle-broken" width={40} /> Agregar Producto 
                        </button>
                    </div>

                    <SubmitButton
                        isDisabled={loading || !productos.every(p => p.titulo && p.precio > 0)}
                        color={!loading && productos.length > 0 && productos.every(p => p.titulo && p.precio > 0) ? "#1b1b1b" : "#ccc"}
                        isLoading={loading}
                        onClick={(e) => { e.preventDefault(); enviarMenu(e); }}
                        className={styles.submitButton}
                    >
                        {productosExistentes.length > 0 ? 'Actualizar Menú' : 'Crear Menú Virtual'}
                    </SubmitButton>
                </form>
            </div>
        </div>
    );
}

export default CrearMenuPage;
