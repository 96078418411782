import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import styles from './GestionRRPP.module.scss';
import variables from "../../Context/Variables";
import Alert from '../../Components/Alert/Alert';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import { Icon } from "@iconify/react";
import CardColaborador from '../../Components/CardColaborador/CardColaborador';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const GestionRRPP = () => {
    const [rrpp, setRRPP] = useState([]);
    const [newRRPPEmail, setNewRRPPEmail] = useState('');
    const [newRRPPName, setNewRRPPName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [mostrarPassword, setMostrarPassword] = useState({});
    const [deletingRrppIds, setDeletingRrppIds] = useState(new Set());
    const navigate = useNavigate();
    const { estaAutenticado, jwt } = useAuth();

    useEffect(() => {
        document.title = "Gestión de RRPP";
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!estaAutenticado) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);
            if (details.rol !== "PRODUCTOR") {
                return <NoPermissionPage />;
            }
            fetchRRPP(jwt);
        }
    }, [navigate, estaAutenticado]);

    const fetchRRPP = async (jwt) => {
        try {
            const response = await axios.get(`${variables.API_BASE_URL}/api/rrpps/list`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            setRRPP(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching RRPP', error);
            setError('Failed to fetch RRPP.');
            setLoading(false);
        }
    };

    const deleteRRPP = async (rrppId) => {
        const result = await Alert({
            tipo: 'warning',
            titulo: '¿Estás seguro?',
            descripcion: "Esto eliminará al RRPP seleccionado y todas sus asociaciones.",
            duracion: 0
        });

        if (result) {
            try {
                await axios.delete(`${variables.API_BASE_URL}/api/rrpps/delete/${rrppId}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                });
                
                await Alert({
                    tipo: 'success',
                    titulo: 'Eliminado!',
                    descripcion: 'El RRPP ha sido eliminado exitosamente.',
                    duracion: 3000
                });
                
                setRRPP(rrpp.filter(member => member.id !== rrppId));
            } catch (error) {
                await Alert({
                    tipo: 'error',
                    titulo: 'Error!',
                    descripcion: 'No se pudo eliminar el RRPP.',
                    duracion: 4000
                });
            }
        }
    };

    const addRRPP = async () => {
        if (!validarEmail(newRRPPEmail)) {
            await Alert({
                tipo: 'warning',
                titulo: 'Email inválido',
                descripcion: 'Por favor, ingresa un email válido.',
                duracion: 3000
            });
            return;
        }

        if (!newRRPPName.trim()) {
            await Alert({
                tipo: 'warning',
                titulo: 'Nombre requerido',
                descripcion: 'Por favor, ingresa un nombre.',
                duracion: 3000
            });
            return;
        }

        setIsAdding(true);
        try {
            const response = await axios.post(`${variables.API_BASE_URL}/api/rrpps/create`, {
                email: newRRPPEmail,
                nombre: newRRPPName
            }, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            if (response.status === 200) {
                await Alert({
                    tipo: 'success',
                    titulo: 'RRPP creado',
                    descripcion: 'El RRPP ha sido creado exitosamente.',
                    duracion: 3000
                });
                fetchRRPP(jwt);
                setNewRRPPEmail('');
                setNewRRPPName('');
            }
        } catch (error) {
            await Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: error.response?.data || 'Error al crear el RRPP',
                duracion: 3000
            });
        } finally {
            setIsAdding(false);
        }
    };

    const validarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const togglePasswordVisibility = (rrppId) => {
        setMostrarPassword(prevState => ({
            ...prevState,
            [rrppId]: !prevState[rrppId]
        }));
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <LoaderSimplepass />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Gestioná tu equipo de RRPP fácilmente
                    <span>Agregá, eliminá y visualizá a todos los RRPP. Estos te acompañaran en todos los eventos que hagas, solo debes asignarlos.</span>
                </h1>
            </div>

            <div className={styles.addStaffSection}>
                <h2 className={styles.sectionTitle}>Agregar miembro</h2>
                <div className={styles.addStaffForm}>
                    <div className={styles.inputGroup}>
                        <Icon icon="solar:user-bold" />
                        <input
                            type="text"
                            placeholder="Nombre del nuevo RRPP"
                            value={newRRPPName}
                            onChange={e => setNewRRPPName(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <Icon icon="material-symbols:mail-outline" />
                        <input
                            type="email"
                            placeholder="Email del nuevo RRPP"
                            value={newRRPPEmail}
                            onChange={e => setNewRRPPEmail(e.target.value)}
                        />
                    </div>
                    <button
                        onClick={addRRPP}
                        disabled={isAdding}
                        className={styles.addButton}
                    >
                        {isAdding ? (
                            <Icon icon="line-md:loading-loop" />
                        ) : (
                            <>Agregar <Icon icon="mingcute:add-fill" /></>
                        )}
                    </button>
                </div>
            </div>

            <h2 className={styles.sectionTitle}>Tus RRPP</h2>
            <div className={styles.staffList}>
                {rrpp.length > 0 ? (
                    rrpp.map(rrppMember => (
                        <CardColaborador
                            key={rrppMember.id}
                            colaborador={{
                                id: rrppMember.id,
                                username: rrppMember.nombre,
                                email: rrppMember.email,
                                contrasena: rrppMember.password
                            }}
                            onUnassign={deleteRRPP}
                            isUnassigning={deletingRrppIds.has(rrppMember.id)}
                            showPassword={mostrarPassword[rrppMember.id]}
                            onTogglePassword={() => togglePasswordVisibility(rrppMember.id)}
                            isInTeamManagement={true}
                        />
                    ))
                ) : (
                    <p className={styles.noStaff}>Aún no tienes RRPP.</p>
                )}
            </div>
        </div>
    );
};

export default GestionRRPP;