import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Alert from '../../Components/Alert/Alert';
import styles from './Caja.module.scss';
import { useAuth } from '../../Context/AuthProvider';
import variables from '../../Context/Variables';
import { Icon } from '@iconify/react/dist/iconify.js';
import ConectorPlugin from '../../Util/ConectorPluginV3';
import CierreLoteModal from '../../Components/CierreLoteModal/CierreLoteModal';
import SkeletonProducto from './SkeletonProducto/SkeletonProducto';

// Función auxiliar para formatear moneda
const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(value);
};

// Función auxiliar para convertir URL a base64
const urlToBase64 = async (url) => {
    url = url + "?nocache=" + new Date().getTime();
    
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error al convertir imagen a base64:', error);
        return null;
    }
};

const Caja = () => {
    const { eventoId, cajaId } = useParams();
    const [productos, setProductos] = useState([]);
    const [error, setError] = useState(null);
    const [errorImpresion, setErrorImpresion] = useState(null);
    const { jwt } = useAuth();
    const [nombreEvento, setNombreEvento] = useState('');
    const [loadingProductId, setLoadingProductId] = useState(null);
    const [impresoras, setImpresoras] = useState([]);
    const [cargandoProductos, setCargandoProductos] = useState(true)
    const [impresoraSeleccionada, setImpresoraSeleccionada] = useState('');
    const [cargandoImpresoras, setCargandoImpresoras] = useState(false);
    const cargandoImpresorasRef = useRef(false);
    const [totalCarrito, setTotalCarrito] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [imagenLogo, setImagenLogo] = useState(null);
    const [noInternetModalVisible, setNoInternetModalVisible] = useState(false);
    const coneccion = useRef(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [base64Logo, setBase64Logo] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [nombreCaja, setNombreCaja] = useState('');
    const menuRef = useRef(null);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);

    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    const userData = JSON.parse(userDetails);

    // Función para verificar la conexión a Internet
    const verificarConexion = () => {
        if (navigator.onLine) {
            coneccion.current = true;
            setNoInternetModalVisible(false);
        } else {
            coneccion.current = false;
            setNoInternetModalVisible(true);
        }
    };

    // Reiniciar la página si no hay internet
    const handleReloadPage = () => {
        window.location.reload();
    };

    // Checkear si hay internet cada vez que se dispare un evento de conexión/desconexión
    useEffect(() => {
        verificarConexion(); 
        window.addEventListener('online', verificarConexion);
        window.addEventListener('offline', verificarConexion);

        return () => {
            window.removeEventListener('online', verificarConexion);
            window.removeEventListener('offline', verificarConexion);
        };
    }, []);

    // Ejecutar la verificación de ventas pendientes cada 60 segundos
    useEffect(() => {
        const interval = setInterval(verificarVentasPendientes, 20000);

        return () => clearInterval(interval);
    }, [eventoId, cajaId, jwt]);

    // Función para cerrar el lote
    const handleCierreLote = async (nombreEncargado, setIsLoading) => {
        setIsLoading(true); // Mostrar el loader
        await verificarVentasPendientes(); // Verificar si hay ventas pendientes y procesarlas

        const productosEnProceso = obtenerProductosEnProceso();
        const ventasPendientes = productosEnProceso.filter(p => p.estado === 'FALLIDO' || p.estado === 'PROCESANDO');

        if (ventasPendientes.length > 0) {
            await Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'Hay ventas pendientes. Espere a que se procesen todas las ventas antes de cerrar el lote.',
                duracion: 5000
            });
            setIsLoading(false);
            return;
        }

        // Cerrar el lote solo cuando no haya ventas pendientes
        try {
            const response = await axios.post(
                `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/cerrarLote`,
                { nombreEncargado },
                { headers: { Authorization: `Bearer ${jwt}` } }
            );

            // Si la respuesta es exitosa, se imprime el ticket
            if (response.status === 200) {
                const cierreLoteData = response.data;
                await Alert({
                    tipo: 'success',
                    titulo: 'Éxito',
                    descripcion: 'Lote cerrado con éxito.',
                    duracion: 3000
                });
                handlePrintTicketCierreLote(cierreLoteData);
                handleCloseModal();
            } else {
                await Alert({
                    tipo: 'error',
                    titulo: 'Error',
                    descripcion: 'No se pudo cerrar el lote. Por favor, inténtalo de nuevo.',
                    duracion: 5000
                });
            }
        } catch (error) {
            await Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudo cerrar el lote. Por favor, inténtalo de nuevo.',
                duracion: 5000
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrintTicketCierreLote = (cierreLoteData) => {
        const { nombreCaja, nombreEvento, nombreEncargado, fechaYHoraRealizado, montoTotalVendido } = cierreLoteData;
        const conector = new ConectorPlugin();

        conector
            .Iniciar()
            .EstablecerAlineacion(ConectorPlugin.ALINEACION_CENTRO)
            .EscribirTexto("_______________________\n")
            .Feed(1)
            .EscribirTexto(`Evento: ${nombreEvento}\n`)
            .Feed(1)
            .EscribirTexto("_______________________\n")
            .Feed(2)
            .EstablecerTamañoFuente(3, 3)
            .EscribirTexto("Cierre de Lote\n")
            .Feed(2)
            .EstablecerTamañoFuente(1, 1)
            .EscribirTexto(`Caja: ${nombreCaja}\n`)
            .EscribirTexto(`Encargado: ${nombreEncargado}\n`)
            .EscribirTexto(`Fecha y hora: ${new Date(fechaYHoraRealizado).toLocaleString()}\n`)
            .Feed(1)
            .EstablecerTamañoFuente(2, 2)
            .EstablecerEnfatizado(true)
            .EscribirTexto(`Total vendido: $${montoTotalVendido.toFixed(0)}\n`)
            .Feed(2)
            .Corte()
            .imprimirEn(impresoraSeleccionada)
            .then(response => {
                console.log("Ticket de cierre de lote impreso exitosamente", response);
            })
            .catch(error => {
                console.error("Error al imprimir el ticket de cierre de lote", error);
            });
    };

    // Listar productos de la caja
    useEffect(() => {
        const fetchProductos = async () => {
            try {
                const response = await axios.get(variables.API_BASE_URL + `/api/cantina/${eventoId}/cajas/${cajaId}/productos`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                });

                if (response.data.productos) {
                    setProductos(response.data.productos);
                    setNombreEvento(response.data.nombreEvento);
                    setNombreCaja(response.data.nombreCaja ? response.data.nombreCaja : '');
                    setCargandoProductos(false);
                } else {
                    setProductos([]);
                    setNombreEvento('');
                    setNombreCaja('');
                    setCargandoProductos(false);
                }

                if (response.data.imagenLogoTicket) {
                    console.log("Imagen logo ticket:", response.data.imagenLogoTicket);
                    setImagenLogo(response.data.imagenLogoTicket);
                } else {
                    setImagenLogo("https://simplepassbucket.s3.amazonaws.com/img/eventos/eventImage_20250110_130631_180_41453863.png");
                }
            } catch (error) {
                setError('Error al cargar los productos de la caja.');
                console.error(error);
                setCargandoProductos(false);
            }
        };

        fetchProductos();
    }, [eventoId, cajaId, jwt]);

    // Listar impresoras una sola vez cuando el componente se monta
    useEffect(() => {
        const obtenerImpresoras = async () => {
            if (!cargandoImpresorasRef.current) {
                cargandoImpresorasRef.current = true;
                setCargandoImpresoras(true);
                try {
                    const impresorasDisponibles = await ConectorPlugin.obtenerImpresoras();
                    setImpresoras(impresorasDisponibles);

                    if (impresorasDisponibles.length === 1) {
                        setImpresoraSeleccionada(impresorasDisponibles[0]);
                    }
                } catch (err) {
                    setErrorImpresion('Error al obtener las impresoras. Verifique que posee el plugin instalado en su computadora.');
                    console.error(err);
                } finally {
                    setCargandoImpresoras(false);
                    cargandoImpresorasRef.current = false;
                }
            }
        };

        obtenerImpresoras();
    }, []);

    const refrescarImpresoras = async () => {
        if (cargandoImpresorasRef.current) {
            return;
        }

        cargandoImpresorasRef.current = true;
        setCargandoImpresoras(true);

        try {
            const impresorasDisponibles = await ConectorPlugin.obtenerImpresoras();
            setImpresoras(impresorasDisponibles);

            if (impresorasDisponibles.length === 1) {
                setImpresoraSeleccionada(impresorasDisponibles[0]);
            }
        } catch (err) {
            setErrorImpresion('Error al obtener las impresoras. Verifique que posee el plugin instalado en su computadora.');
            console.error(err);
        } finally {
            setCargandoImpresoras(false);
            cargandoImpresorasRef.current = false;
        }
    };

    // Función para generar un ID único
    const generarIdUnico = () => {
        return Math.random().toString(36).substring(2, 18);
    };

    const handleProductClick = async (producto) => {
        if (!impresoraSeleccionada) {
            Alert({
                tipo: 'warning',
                titulo: 'Atención',
                descripcion: 'Por favor seleccione una impresora antes de proceder.',
                duracion: 3000
            });
            return;
        }

        verificarConexion();
        if (!coneccion.current) {
            setNoInternetModalVisible(true);
            return;
        }

        setLoadingProductId(producto.id);

        const idFront = generarIdUnico();
        // Guardar una copia completa y actualizada del producto
        const nuevoProducto = { 
            ...producto,
            idFront: idFront, 
            estado: 'PROCESANDO',
            productoId: producto.id, // Guardamos explícitamente el ID del producto
            timestamp: new Date().getTime() // Agregamos timestamp para control
        };

        const productosEnProceso = obtenerProductosEnProceso();
        const productosActualizados = [...productosEnProceso, nuevoProducto];
        
        // Limpiamos productos antiguos (más de 24 horas)
        const productosFiltrados = productosActualizados.filter(p => {
            const horasTranscurridas = (new Date().getTime() - p.timestamp) / (1000 * 60 * 60);
            return horasTranscurridas < 24;
        });
        
        localStorage.setItem('productosEnProceso', JSON.stringify(productosFiltrados));

        handlePrintTicket(producto);
        setTotalCarrito(prevTotal => prevTotal + producto.precio);

        setTimeout(() => {
            setLoadingProductId(null);
        }, 1000);

        setTimeout(async () => {
            try {
                console.log("Producto ID:", nuevoProducto.productoId, "ID Front:", idFront);
                console.log("Enviando solicitud a:", `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/venta`);

                const response = await axios.post(
                    `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/venta`,
                    { 
                        productoId: nuevoProducto.productoId, // Usamos el ID guardado
                        idFront: nuevoProducto.idFront 
                    },
                    { headers: { Authorization: `Bearer ${jwt}` } }
                );

                if (response.status === 201 || response.status === 200) {
                    const productosEnProcesoActuales = obtenerProductosEnProceso();
                    const productosFiltrados = productosEnProcesoActuales.filter(p => p.idFront !== nuevoProducto.idFront);
                    localStorage.setItem('productosEnProceso', JSON.stringify(productosFiltrados));
                } else {
                    actualizarEstadoProducto(nuevoProducto.idFront, 'FALLIDO');
                }
            } catch (error) {
                console.error('Error durante la solicitud de venta:', error);
                actualizarEstadoProducto(nuevoProducto.idFront, 'FALLIDO');
            }
        }, 0);
    };

    const handleResetTotal = () => {
        setTotalCarrito(0);
    };

    const handlePrintTicket = async (producto) => {
        try {
            const conector = new ConectorPlugin();
            
            // Intenta imprimir con el logo personalizado
            if (base64Logo) {
                conector
                    .Iniciar()
                    .EstablecerAlineacion(ConectorPlugin.ALINEACION_CENTRO)
                    .Feed(1)
                    .ImprimirImagenEnBase64(base64Logo, 200, ConectorPlugin.ALGORITMO_IMAGEN_RASTERIZACION)
                    .Feed(1)
                    .EscribirTexto('____________')
                    .Feed(2)
                    .EstablecerTamañoFuente(2, 2)
                    .EstablecerEnfatizado(true)
                    .EscribirTexto(`${producto.titulo}\n`)
                    .Feed(2)
                    .EstablecerTamañoFuente(1, 1)
                    .EscribirTexto(`$${producto.precio.toFixed(0)}\n`)
                    .Feed(1)
                    .EstablecerTamañoFuente(1, 1)
                    .Feed(1)
                    .EscribirTexto('simplepass.com.ar\n')
                    .Feed(1)
                    .EscribirTexto('----------------------')
                    .Feed(2)
                    .Corte()
                    .imprimirEn(impresoraSeleccionada)
                    .catch(() => {
                        // Si falla, intentar con el ticket simple
                        handlePrintSimpleTicket(producto);
                    });
            } else {
                // Si no hay logo, usar el ticket simple
                handlePrintSimpleTicket(producto);
            }
        } catch (error) {
            console.error("Error al imprimir el ticket", error);
            // En caso de error, intentar con el ticket simple
            handlePrintSimpleTicket(producto);
        }
    };

    const handlePrintSimpleTicket = (producto) => {
        const conector = new ConectorPlugin();
        
        conector
            .Iniciar()
            .EstablecerAlineacion(ConectorPlugin.ALINEACION_CENTRO)
            .Feed(1)
            .EstablecerEnfatizado(true)
            .EscribirTexto(`${nombreEvento}\n`)
            .Feed(1)
            .EscribirTexto('______________________')
            .Feed(2)
            .EstablecerTamañoFuente(2, 2)
            .EstablecerEnfatizado(true)
            .EscribirTexto(`${producto.titulo}\n`)
            .Feed(2)
            .EstablecerTamañoFuente(1, 1)
            .EscribirTexto(`$${producto.precio.toFixed(0)}\n`)
            .Feed(1)
            .EstablecerTamañoFuente(1, 1)
            .Feed(1)
            .EscribirTexto('simplepass.com.ar\n')
            .Feed(2)
            .EscribirTexto('-------------------')
            .Feed(1)
            .Corte()
            .imprimirEn(impresoraSeleccionada)
            .then(response => {
                console.log("Respuesta de impresora (ticket simple):", response);
            })
            .catch(error => {
                console.error("Error al imprimir el ticket simple", error);
            });
    };

    // Función para actualizar el estado del producto en el localStorage
    const actualizarEstadoProducto = (idFrontParam, nuevoEstado) => {

        const productosEnProceso = obtenerProductosEnProceso();

        // Hacemos una copia de la lista actual de productos en proceso
        const productosActualizados = productosEnProceso.map(p => {

            // Verificamos si el idFront coincide con el que queremos actualizar
            if (p.idFront == idFrontParam) {

                // Solo actualizamos el estado del producto encontrado
                return { ...p, estado: nuevoEstado };
            }
            // Si no es el producto que buscamos, lo dejamos intacto
            return p;
        });

        // Guardamos la lista actualizada en localStorage
        localStorage.setItem('productosEnProceso', JSON.stringify(productosActualizados));
    };

    // Obtener los productos en proceso directamente desde localStorage
    const obtenerProductosEnProceso = () => {
        return JSON.parse(localStorage.getItem('productosEnProceso')) || [];
    };

    // Método para verificar ventas pendientes y procesarlas de una en una
    const verificarVentasPendientes = async () => {
        // Buscamos la lista de pendientes en localstorage
        const productosEnProceso = obtenerProductosEnProceso();
        console.log('Productos en proceso encontrados:', productosEnProceso);

        // Si posee fallidos o pendientes entonces se deben registrar uno a uno
        if (productosEnProceso.length > 0) {
            for (const venta of productosEnProceso) {
                console.log('Intentando procesar venta:', {
                    productoId: venta.productoId,
                    idFront: venta.idFront,
                    estado: venta.estado
                });

                try {
                    const response = await axios.post(
                        `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/venta`,
                        { 
                            productoId: venta.productoId,
                            idFront: venta.idFront 
                        },
                        {
                            headers: { Authorization: `Bearer ${jwt}` }
                        }
                    );

                    console.log('Respuesta del servidor:', response.status, response.data);

                    if (response.status === 201 || response.status === 200) {
                        const productosActualizados = productosEnProceso.filter(p => p.idFront !== venta.idFront);
                        console.log('Productos actualizados después de procesar:', productosActualizados);
                        localStorage.setItem('productosEnProceso', JSON.stringify(productosActualizados));
                    }
                } catch (error) {
                    console.error('Error detallado al verificar venta:', {
                        venta,
                        error: error.response ? error.response.data : error.message,
                        status: error.response ? error.response.status : null
                    });
                }
            }
        }
    };

    // Convertir imagen a base64 cuando se carga el componente
    useEffect(() => {
        const convertirImagen = async () => {
            if (imagenLogo) {
                const base64 = await urlToBase64(imagenLogo);
                setBase64Logo(base64);
            }
        };
        convertirImagen();
    }, [imagenLogo]);

    // Actualizar el estado cuando cambia el modo de pantalla completa
    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        return () => document.removeEventListener('fullscreenchange', handleFullScreenChange);
    }, []);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen()
                .catch(err => {
                    console.error(`Error al intentar pantalla completa: ${err.message}`);
                });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
                    .catch(err => {
                        console.error(`Error al salir de pantalla completa: ${err.message}`);
                    });
            }
        }
    };

    useEffect(() => {
        // Manejador para cerrar el menú cuando se hace clic fuera
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        // Agregar el event listener cuando el menú está abierto
        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup del event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <div className={styles.cajaContainer}>

            {/* Header */}
            <div className={styles.header}>
                <div className={styles.leftButtons}>
                    <button className={styles.volver} onClick={() => window.history.back()}>
                        <Icon width={30} icon="solar:arrow-left-linear" />
                    </button>
                    <button className={styles.fullscreenBtn} onClick={toggleFullScreen}>
                        <Icon 
                            width={30} 
                            icon={isFullScreen ? "solar:minimize-square-linear" : "solar:maximize-square-linear"}
                        />
                    </button>
                </div>
                <div className={styles.cajaNombre}>
                    <h2 className={styles.nombreCaja}>{nombreCaja}</h2>
                </div>
                <div className={styles.menuContainer} ref={menuRef}>
                    <button 
                        className={styles.menuButton} 
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <Icon icon="material-symbols:menu" width="24" />
                    </button>
                    
                    {menuOpen && (
                        <div className={styles.menuDropdown}>
                            <ul>
                                {userData.rol === "PRODUCTOR" && (
                                    <li>
                                        <Link to={`/productos-caja/${eventoId}/${cajaId}`}>
                                            <Icon width={20} icon="solar:bottle-linear" /> Gestión productos
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <button onClick={handleOpenModal} >
                                        <Icon icon="uil:money-withdrawal" width={20} /> Cerrar Lote
                                    </button>
                                </li>
                                <li>
                                    <div className={styles.impresorasContainer}>
                                        <h2 className={styles.subtituloImpresoras}>
                                            Lista de Impresoras <Icon icon="mdi:printer-pos-outline" width={20} />
                                        </h2>
                                        {!impresoraSeleccionada && errorImpresion && (
                                            <p style={{ fontSize: "14px", color: "red", textAlign: "center", fontWeight: "600" }}>
                                                {errorImpresion}
                                            </p>
                                        )}
                                        {cargandoImpresoras ? (
                                            <div className={styles.loader}></div>
                                        ) : (
                                            <div className={styles.impresorasList}>
                                                {impresoras.length > 0 ? (
                                                    impresoras.map((impresora, index) => (
                                                        <p
                                                            key={index}
                                                            className={`${styles.impresoraItem} ${impresoraSeleccionada === impresora ? styles.selected : ''
                                                                }`}
                                                            onClick={() => setImpresoraSeleccionada(impresora)}
                                                        >
                                                            {impresoraSeleccionada === impresora ? (
                                                                <Icon icon="entypo:check" width={16} />
                                                            ) : (
                                                                <Icon icon="mdi:printer-pos-plus-outline" width={16} />
                                                            )}
                                                            {impresora}
                                                        </p>
                                                    ))
                                                ) : (
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "red",
                                                            textAlign: "center",
                                                            fontWeight: "600",
                                                        }}
                                                        className={styles.noHay}
                                                    >
                                                        No hay impresoras disponibles.
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        <button onClick={refrescarImpresoras} className={styles.refrescar}>
                                            <Icon icon="solar:restart-outline" width={16} /> Refrescar
                                        </button>
                                    </div>
                                </li>
                                {userData.rol === "PRODUCTOR" && (
                                    <li>
                                        <Link to={`/informe-caja/${eventoId}/${cajaId}`}>
                                            <Icon icon="solar:chart-outline" width={20} /> Informe completo
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.contenedorBody}>
                {/* Productos */}
                <div className={styles.productos}>
                    <h3 className={styles.subtituloProducto}>Productos</h3>
                    {error && <p>{error}</p>}
                    <div className={styles.productosGrid}>
                        {cargandoProductos
                            ? Array.from({ length: 10 }).map((_, index) => (
                                <SkeletonProducto key={index} />
                            ))
                            : productos.map((producto) => (
                                <div
                                    key={producto.id}
                                    className={styles.producto}
                                    onClick={() => handleProductClick(producto)}
                                >
                                    {loadingProductId === producto.id ? (
                                        <div className={styles.loader}></div>
                                    ) : (
                                        <>
                                            <img
                                                src={producto.imgURL}
                                                alt={producto.titulo}
                                                className={styles.productoImagen}
                                            />
                                            <div className={styles.datos}>
                                                <p>{producto.titulo}</p>
                                                <p>${producto.precio.toFixed(0)}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>

                {/* Total a Cobrar */}
                <div className={styles.panelLateral}>
                    <div className={`${styles.totalContainer} ${menuOpen ? styles.menuOpen : ''}`}>
                        <h3 className={styles.aCobrar}>Total a cobrar</h3>
                        <div className={styles.row}>
                            <span className={styles.monto}>
                                {totalCarrito.toFixed(0)}
                            </span>
                            <Icon
                                className={styles.reload}
                                width={60}
                                icon="solar:restart-circle-bold"
                                onClick={handleResetTotal}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                </div>

                {/* Modal de cierre de lote */}
                <CierreLoteModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    onSubmit={handleCierreLote}
                />

                {/* Modal de no conexión */}
                {noInternetModalVisible && (
                    <div className={styles.modalBackground}>
                        <div className={styles.modalContent}>
                            <Icon className={styles.wifi} width={70} icon="clarity:no-wifi-solid" />
                            <p>No hay internet, verifique la conexión y recargue la página.</p>
                            <button className={styles.recargar} onClick={handleReloadPage}>
                                Recargar página <Icon icon="ci:arrows-reload-01" />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

};

export default Caja;