import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import styles from './EventoConfiguracion.module.scss';

const EventoConfiguracion = () => {
    const { eventoId, nombre, imgUrl } = useParams();
    const [imagenUrl, setImagenUrl] = useState(null);

    const decodedNombre = decodeURIComponent(nombre);
    const decodedImgUrl = decodeURIComponent(imgUrl);
    const encodedName = encodeURIComponent(decodedNombre)

    useEffect(() => {
        document.title = "Configuraciones para: " + nombre;
        window.scroll(0, 0);
        if (imgUrl) {
            setImagenUrl(decodedImgUrl);
        } else {
            setImagenUrl('https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Banner+evento+sin+imagen/banner-evento-por-defeecto.webp');
        }
    }, []);


    const botones = [
        { to: `/crear-menu/${eventoId}`, icon: "solar:bottle-outline", texto: "Consumiciones", descripcion: "Administrar la venta de consumiciones online." },
        { to: `/gestion-rrpp-evento/${eventoId}/${encodedName}`, icon: "material-symbols:groups-2-outline", texto: "RRPPs", descripcion: "Administra el equipo relaciones públicas y sus links de venta." },
        { to: `/gestion-staff-evento/${eventoId}/${encodedName}`, icon: "carbon:group-access", texto: "STAFF", descripcion: "Gestionar el equipo de trabajo asignado a escanear." },
        { to: `/gestion-tickets/${eventoId}/${encodedName}`, icon: "material-symbols:confirmation-number", texto: "Tickets", descripcion: "Gestionar precios, disponibilidad y titulos de los tickets a la venta." },
        { to: `/enviar-qrs/${eventoId}`, icon: "material-symbols:qr-code", texto: "Enviar QRs.", descripcion: "Generar y enviar códigos QR de manera directa." },
        { to: `/gestion-mesas/${eventoId}`, icon: "vs:table", texto: "Mesas", descripcion: "Administrar la venta y asignación de mesas." },
        { to: `/editar-evento/${eventoId}`, icon: "material-symbols:edit", texto: "Editar", descripcion: "Modificar los datos del evento." },
        { to: `/informe-ventas/${eventoId}`, icon: "octicon:graph", texto: "Ventas", descripcion: "Ver el informe de ventas del evento." },
        { to: `/evento-pagos/${eventoId}/${encodedName}`, icon: "ph:list-checks", texto: "Pagos", descripcion: "Ver la lista de todos los pagos en detalle." },
        { to: `/cajas/${eventoId}/${encodedName}`, icon: "ph:cash-register-bold", texto: "Cantina", descripcion: "Agregar, ver, editar y vender productos en efectivo." },
        { to: `/gestion-butacas/${eventoId}/${encodedName}`, icon: "bx:chair", texto: "Butacas", descripcion: "Gestionar la disponibilidad de butacas." },
    ];

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.eventInfo}>
                    <img src={imagenUrl} alt="Event" className={styles.eventImage} />
                    <div>
                        <h1 className={styles.eventName}>{decodedNombre}</h1>
                        <p className={styles.eventId}>ID: {eventoId}</p>
                    </div>
                </div>

                <Icon color='#ccc' width={35} icon="lucide:settings" />

            </div>
            <div className={styles.grid}>
                {botones.map((boton, index) => (
                    <Link key={index} to={boton.to} className={styles.gridItem}>
                        <div className={styles.iconContainer}>
                            <Icon width={30} icon={boton.icon} style={{ width: '30px' }} />
                        </div>
                        <h3>{boton.texto}</h3>
                        <p className={styles.description}>{boton.descripcion}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default EventoConfiguracion;