import React from "react";
import styles from "./InformeVentas.module.scss";
import { Icon } from "@iconify/react";
import html2pdf from 'html2pdf.js';
import InformeVentasPDF from "./InformeVentasPDF";

// Función auxiliar para formatear moneda
const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(value);
};

// Función proporcionada para formatear fechas
const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hour, minute] = timePart.split(":");
    const date = new Date(year, month - 1, day, hour, minute);
    return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short",
    })} ${date.getFullYear()}`;
};

const InformeVentas = ({ datos, eventoId }) => {
    if (!datos) {
        return <div style={{ color: "red" }}>No se encontró ningún evento.</div>;
    }

    const handleDownloadPDF = () => {
        const element = document.getElementById('informe-para-pdf');
        const opt = {
            margin: 10,
            filename: `informe-${datos.tituloEvento}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { 
                scale: 2,
                useCORS: true,
            },
            jsPDF: { 
                unit: 'mm', 
                format: 'a4', 
                orientation: 'portrait'
            }
        };

        html2pdf().set(opt).from(element).save();
    };

    return (
        <div className={styles.body}>
            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <h1 className={styles.InformeVentasH1}>
                        Informe de ventas <br /> 
                        <span>{datos.tituloEvento}</span> - <span>{formatDate(datos.fechaRealizazion)}</span>
                    </h1>
                </div>
                <button onClick={handleDownloadPDF} className={styles.pdfButton}>
                    <Icon icon="fa6-regular:file-pdf" className={styles.pdfIcon} />
                </button>
            </div>

            <div className={styles.seccionCards}>
                <div className={`${styles.cardVentas} ${styles.cardVentasTotales}`}>
                    <h5>Ventas totales</h5>
                    <p>{formatCurrency(datos.ventasTotales)}</p>
                </div>
                <div className={`${styles.cardVentas} ${styles.cardVentaALiquidar}`}>
                    <h5>A liquidar por ventas*:</h5>
                    <p>{formatCurrency(datos.netoARecibir)}</p>
                </div>
                <div className={`${styles.cardVentas} ${styles.cardVentaComisiones}`}>
                    <h5>Comisiones de simplepass:</h5>
                    <p>{formatCurrency(datos.totalComiciones)}</p>
                </div>
            </div>
            <p className={styles.aclaracion}>
                *"A liquidar por ventas" es el monto neto que el productor obtiene del
                evento, una vez descontadas las comisiones.
            </p>

            {/* Tabla de Tickets Vendidos */}
            {datos.cantidadTicketsVendidos &&
                datos.cantidadTicketsVendidos.length > 0 && (
                    <section className={styles.seccionDeInforme}>
                        <h2>Cantidades de tickets vendidos</h2>
                        <table className={styles.tablaInformeCantidades}>
                            <thead>
                                <tr>
                                    <th className={styles.brtopleft}>Tipo</th>
                                    <th className={styles.br0}>Cantidad</th>
                                    <th className={styles.br0}>Precio</th>
                                    <th className={styles.brtopright}>Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.cantidadTicketsVendidos.map((ticket, index) => (
                                    <tr key={index}>
                                        <td className={styles.brleft}>{ticket.nombreTipoTicket}</td>
                                        <td>{ticket.cantidadVendida}</td>
                                        <td>{formatCurrency(ticket.precio)}</td>
                                        <td>{formatCurrency(ticket.total)}</td>
                                    </tr>
                                ))}
                                <tr className={styles.totalRow}>
                                    <td colSpan="3" className={styles.totalLabel}>
                                        Total tickets:
                                    </td>
                                    <td>
                                        {formatCurrency(datos.cantidadTicketsVendidos.reduce((acc, ticket) => acc + ticket.total, 0))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                )}

            {/* Tabla de Ticket Enviados Directamente  */}
            {datos.cantidadTicketsVendidos &&
                datos.cantidadTicketsVendidos.some(ticket => ticket.cantidadEnviadaDirectamente > 0) && (
                    <section className={styles.seccionDeInforme}>
                        <h2>Tickets enviados por el administrador</h2>
                        <table className={styles.tablaInformeCantidades}>
                            <thead>
                                <tr>
                                    <th className={styles.brtopleft}>Tipo</th>
                                    <th className={styles.brtopright}>Cantidad QRs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.cantidadTicketsVendidos.filter(ticket => ticket.cantidadEnviadaDirectamente > 0).map((ticket, index) => (
                                    <tr key={index}>
                                        <td className={styles.brbottomleft}>{ticket.nombreTipoTicket}</td>
                                        <td className={styles.brbottomright}>{ticket.cantidadEnviadaDirectamente}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                )}


            {/* Tabla de Butacas Vendidas */}
            {datos.resumenButacasVendidas && datos.resumenButacasVendidas.length > 0 && (
                <section className={styles.seccionDeInforme}>
                    <h2>Cantidades de butacas vendidas</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Sección</th>
                                <th className={styles.br0}>Fila</th>
                                <th className={styles.br0}>Cantidad</th>
                                <th className={styles.br0}>Precio</th>
                                <th className={styles.brtopright}>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenButacasVendidas.map((butaca, index) => (
                                <tr key={index}>
                                    <td>{butaca.seccion}</td>
                                    <td>{butaca.fila}</td>
                                    <td>{butaca.totalVendidas}</td>
                                    <td>{formatCurrency(butaca.precio)}</td>
                                    <td>{formatCurrency(butaca.total)}</td>
                                </tr>
                            ))}
                            <tr className={styles.totalRow}>
                                <td colSpan="4" className={styles.totalLabel}>
                                    Total butacas:
                                </td>
                                <td>
                                    {formatCurrency(datos.resumenButacasVendidas.reduce((acc, butaca) => acc + butaca.total, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            )}

            {/* Tabla de Mesas Vendidas */}
            {datos.resumenMesasVendidas && datos.resumenMesasVendidas.length > 0 && (
                <section className={styles.seccionDeInforme}>
                    <h2>Cantidades de mesas vendidas</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Nombre</th>
                                <th className={styles.br0}>Cantidad</th>
                                <th className={styles.br0}>Precio</th>
                                <th className={styles.brtopright}>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenMesasVendidas.map((mesa, index) => (
                                <tr key={index}>
                                    <td>{mesa.nombre}</td>
                                    <td>{mesa.totalVendidas}</td>
                                    <td>{formatCurrency(mesa.precio)}</td>
                                    <td>{formatCurrency(mesa.total)}</td>
                                </tr>
                            ))}
                            <tr className={styles.totalRow}>
                                <td colSpan="3" className={styles.totalLabel}>
                                    Total mesas:
                                </td>
                                <td>
                                    {formatCurrency(datos.resumenMesasVendidas.reduce((acc, mesa) => acc + mesa.total, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            )}

            {/* Tabla de Consumiciones Vendidas */}
            {datos.resumenProductosConsumidos && datos.resumenProductosConsumidos.length > 0 && (
                <section className={styles.seccionDeInforme}>
                    <h2>Cantidades de consumiciones vendidas</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Consumición</th>
                                <th className={styles.br0}>Cantidad</th>
                                <th className={styles.br0}>Precio</th>
                                <th className={styles.brtopright}>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenProductosConsumidos.map((producto, index) => (
                                <tr key={index}>
                                    <td>{producto.nombreProducto}</td>
                                    <td>{producto.cantidad}</td>
                                    <td>{formatCurrency(producto.precio)}</td>
                                    <td>{formatCurrency(producto.cantidad * producto.precio)}</td>
                                </tr>
                            ))}
                            <tr className={styles.totalRow}>
                                <td colSpan="3" className={styles.totalLabel}>
                                    Total consumiciones:
                                </td>
                                <td>
                                    {formatCurrency(datos.resumenProductosConsumidos.reduce((acc, producto) => acc + (producto.cantidad * producto.precio), 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            )}

            {/* Tabla de Ventas por RRPP */}
            {datos.ventasPorRrpp && datos.ventasPorRrpp.length > 0 && (
                <section className={styles.seccionDeInforme}>
                    <h2>Ventas por RRPP</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Nombre</th>
                                <th className={styles.br0}>Cantidad Tickets</th>
                                <th className={styles.brtopright}>Total Vendido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.ventasPorRrpp.map((rrpp, index) => (
                                <tr key={index}>
                                    <td>{rrpp.nombre}</td>
                                    <td>{rrpp.cantidadTickets}</td>
                                    <td>{formatCurrency(rrpp.totalVendido)}</td>
                                </tr>
                            ))}
                            <tr className={styles.totalRow}>
                                <td colSpan="2" className={styles.totalLabel}>
                                    Total ventas RRPP:
                                </td>
                                <td>
                                    {formatCurrency(datos.ventasPorRrpp.reduce((acc, rrpp) => acc + rrpp.totalVendido, 0))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            )}

            {/* Componente PDF oculto */}
            <div style={{ display: 'none' }}>
                <InformeVentasPDF datos={datos} />
            </div>
        </div>
    );
};

export default InformeVentas;