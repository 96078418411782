import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import styles from './ExitoPago.module.scss';
import { Icon } from "@iconify/react";


const ExitoPago = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const status = queryParams.get('status');
    const text = queryParams.get('text');
    const id = queryParams.get('id');
    const total = queryParams.get('total');
    const sourceName = queryParams.get('sourceName');
    const transactionId = queryParams.get('transactionId');

    useEffect(() => {
        document.title = "Compra exitosa";
    }, []);

    return (
        <main className={styles.exitoBody}>
            <section className={styles.bodyDetalle}>
                <h1>
                    Tu pago fue aprobado <Icon icon="solar:check-circle-bold" style={{ color: '#47BD8F' }} />
                </h1>
                <div className={styles.fondoSub}>
                    <h4 className={styles.aclaracionExito}>
                        Tus tickets están siendo procesados, en 5 minutos podrás verlos y además te llegarán a tu correo.
                    </h4>
                </div>
                <div className={styles.tablaDetallePago}>
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan="2">Detalles del pago:</th>
                            </tr>
                            <tr>
                                <td>Pago id:</td>
                                <td className={styles.resultado}>{id}</td>
                            </tr>
                            <tr>
                                <td>Medio de pago:</td>
                                <td className={styles.resultado}>{decodeURIComponent(sourceName)}</td>
                            </tr>
                            <tr>
                                <td>Estado del pago:</td>
                                <td className={styles.resultado}>{text}</td>
                            </tr>
                            <tr className={styles.br}>
                                <td className={styles.br10bl}>Total:</td>
                                <td className={styles.resultado}>${total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Link to="/mis-tickets" className={`${styles.botonVerTickets} ${styles.btnExitoQrs}`}>
                    <Icon icon="solar:qr-code-outline" /> Ver mis QRs
                </Link>
            </section>
        </main>
    );
};

export default ExitoPago;