import React, { useEffect } from 'react'
import styles from "./ExitoCreacionEvento.module.scss"
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

const ExitoCreacionEvento = () => {

  //Modificacion de head
  useEffect(() => {
    document.title = "Cuenta vinculada exitosamente";
  }, []);

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className={styles.eventoCreadoSection}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className={styles.container}
      >

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className={styles.content}
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 260, damping: 20 }}
            className={styles.successIcon}
          >
            <Icon icon="mdi:check-circle" />
          </motion.div>
          <h2>¡Vinculación Exitosa!</h2>
          <div className={styles.mpContainer}>
            <img
              src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/Mercado-Pago-Logo.webp"
              alt="Mercado Pago Logo"
              className={styles.mpLogo}
            />
            <p>Tu cuenta de Mercado Pago ha sido conectada correctamente</p>
          </div>

          <div className={styles.infoCard}>
            <Icon icon="mdi:information" className={styles.infoIcon} />
            <p>Estamos configurando los últimos detalles para que puedas empezar a recibir pagos en tiempo real.</p>
          </div>

          <div className={styles.contactSection}>
            <h3>¿Necesitas ayuda?</h3>
            <div className={styles.contactLinks}>
              <a href="https://wa.me/543434653077" target="_blank" rel="noopener noreferrer">
                <Icon icon="bi:whatsapp" /> +54 343 465-3077
              </a>
              <a href="mailto:simplesistemasweb@gmail.com">
                <Icon icon="solar:letter-unread-bold" /> simplesistemasweb@gmail.com
              </a>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default ExitoCreacionEvento
