import React, { useState, useEffect } from 'react';
import { Icon } from "@iconify/react";
import styles from './CardColaborador.module.scss';

const CardColaborador = ({ 
    colaborador, 
    onUnassign, 
    onAssign, 
    isUnassigning, 
    isAssigning, 
    showPassword, 
    onTogglePassword,
    isAssignedSection,
    isInTeamManagement = false,
    onVerInforme
}) => {
    const [copiedEmail, setCopiedEmail] = useState(false);
    const [copiedPassword, setCopiedPassword] = useState(false);

    const handleCopyEmail = async () => {
        await navigator.clipboard.writeText(colaborador.email);
        setCopiedEmail(true);
        setTimeout(() => setCopiedEmail(false), 2000);
    };

    useEffect(() => {
        console.log(colaborador);
    }, [colaborador]);

    const handleCopyPassword = async () => {
        await navigator.clipboard.writeText(colaborador.contrasena);
        setCopiedPassword(true);
        setTimeout(() => setCopiedPassword(false), 2000);
    };

    return (
        <div className={styles.staffCard}>
            <div className={styles.staffHeader}>
                <div className={styles.userInfo}>
                    <Icon 
                        icon={isInTeamManagement ? "solar:users-group-rounded-linear" : "solar:user-bold"} 
                        className={styles.userIcon} 
                    />
                    <h3>{colaborador.username}</h3>
                </div>
                {isInTeamManagement ? (
                    <button
                        className={styles.deleteButton}
                        onClick={() => onUnassign(colaborador.id)}
                        disabled={isUnassigning}
                    >
                        {isUnassigning ?
                            <Icon icon="line-md:loading-loop" /> :
                            <Icon icon="ph:trash" />
                        }
                    </button>
                ) : isAssignedSection ? (
                    <button
                        className={styles.deleteButton}
                        onClick={() => onUnassign(colaborador.id)}
                        disabled={isUnassigning}
                    >
                        {isUnassigning ?
                            <Icon icon="line-md:loading-loop" /> :
                            <Icon icon="solar:user-block-linear" />
                        }
                    </button>
                ) : (
                    <button
                        className={styles.addButton}
                        onClick={() => onAssign(colaborador.id)}
                        disabled={isAssigning}
                    >
                        {isAssigning ?
                            <Icon icon="line-md:loading-loop" /> :
                            <Icon icon="mingcute:add-fill" />
                        }
                    </button>
                )}
            </div>
            <div className={styles.infoContainer}>
                <div className={`${styles.infoRow} ${styles.idRow}`}>
                    <span className={styles.label}>ID</span>
                    <span className={styles.value}>{colaborador.id}</span>
                </div>
                <div className={styles.infoRow}>
                    <span className={styles.label}>Email</span>
                    <div className={styles.valueWithCopy}>
                        <span className={styles.value}>{colaborador.email}</span>
                        <button 
                            onClick={handleCopyEmail}
                            className={styles.copyButton}
                            aria-label="Copiar email"
                        >
                            <Icon 
                                icon={copiedEmail ? "tabler:check" : "mdi:content-copy"} 
                            />
                        </button>
                    </div>
                </div>
                {isAssignedSection && (
                    <div className={styles.infoRow}>
                        <span className={styles.label}>Contraseña</span>
                        <div className={styles.passwordField}>
                            <input
                                type={showPassword ? "text" : "password"}
                                value={colaborador.contrasena}
                                readOnly
                            />
                            <button
                                onClick={onTogglePassword}
                                aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                            >
                                <Icon
                                    icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                                    width={20}
                                />
                            </button>
                            <button 
                                onClick={handleCopyPassword}
                                className={styles.copyButton}
                                aria-label="Copiar contraseña"
                            >
                                <Icon 
                                    icon={copiedPassword ? "mdi:check" : "mdi:content-copy"} 
                                    className={copiedPassword ? styles.copied : ''}
                                />
                            </button>
                        </div>
                    </div>
                )}
                {isAssignedSection && onVerInforme && (
                    <button 
                        onClick={onVerInforme}
                        className={styles.reportButton}
                    >
                        Ver informe de ventas <Icon icon="solar:chart-2-linear" className={styles.reportIcon} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default CardColaborador; 