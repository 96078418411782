import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import styles from "./Footer.module.scss";

const Footer = () => {
  const navigate = useNavigate();

  const handleLinkClick = (path) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setTimeout(() => {
      navigate(path);
    }, 500); 
  };

  return (
    <>
      <footer className={styles.footerContainer}>
        {/* Logo */}
        <article className={styles.logoContainer}>
          <h1 className={styles.logo}>
            <span className={styles.logoSimple}>simple</span>
            <span className={styles.logoPass}>pass</span>
          </h1>
        </article>

        {/* Enlaces */}
        <article className={styles.linkContainer}>
          <span className={styles.link} onClick={() => handleLinkClick("/nosotros")}>
            ¿Quiénes somos?
          </span>
          <span className={styles.link} onClick={() => handleLinkClick("/signup-productor")}>
            Registrarme como productor
          </span>
          <span className={styles.link} onClick={() => handleLinkClick("/faq")}>
            Preguntas frecuentes
          </span>
          <span className={styles.link} onClick={() => handleLinkClick("/terminosYCondiciones")}>
            Términos y Condiciones
          </span>
          <span className={styles.link} onClick={() => handleLinkClick("/terminosYCondiciones")}>
            Política de privacidad
          </span>
          <span className={styles.link} onClick={() => handleLinkClick("/trabaja-con-nosotros")}>
            Unite al equipo
          </span>
        </article>

        <div className={styles.linea}></div>

        {/* Parte inferior con redes sociales y copyright */}
        <article className={styles.footerBottom}>
          <span className={styles.copyright}>© 2024 Copyright, HERGON SOFT S.A.S todos los derechos reservados.</span>
          <div className={styles.socialMediaContainer}>
            <a href="https://www.facebook.com/profile.php?id=100076488752786" target="_blank" rel="noopener noreferrer">
              <Icon width={25} icon="fa6-brands:facebook" />
            </a>
            <a href="https://www.instagram.com/simplepass.com.ar?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <Icon width={25} icon="bi:instagram" />
            </a>
            <a href="https://www.linkedin.com/company/hergon-soft-s-a-s" target="_blank" rel="noopener noreferrer">
              <Icon width={25} icon="bi:linkedin" />
            </a>
          </div>
        </article>
      </footer>
    </>
  );
};

export default Footer;
