import React, { createContext, useContext, useState, useEffect, useCallback } from "react";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [jwt, setJwt] = useState(() => {
    const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    return userDetails ? JSON.parse(userDetails).jwt : null;
  });

  const [estaAutenticado, setEstaAutenticado] = useState(jwt !== null);
  const [necesitaCompletarInformacion, setNecesitaCompletarInformacion] = useState(() => {
    const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    return userDetails ? JSON.parse(userDetails).necesitaCompletarInformacion : null;
  });

  const [necesitaAceptarTerminos, setNecesitaAceptarTerminos] = useState(() => {
    const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    return userDetails ? JSON.parse(userDetails).necesitaAceptarTerminos : null;
  });


  const actualizarLastActivity = useCallback(() => {
    const now = new Date().getTime();
    const storage = sessionStorage.getItem("userDetails") ? sessionStorage : localStorage;
    if (storage === sessionStorage) {
      storage.setItem("lastActivity", now.toString());
    }
  }, []);

  const logout = useCallback(() => {
    sessionStorage.clear();
    localStorage.removeItem("userDetails");
    localStorage.removeItem("lastActivity");
    setJwt(null);
    setEstaAutenticado(false);
    window.location.href = "/login";
  }, []);

  const login = useCallback((usuario, mantenerSesion, redireccionar) => {
    setJwt(usuario.jwt);
    setEstaAutenticado(true);
    setNecesitaCompletarInformacion(usuario.necesitaCompletarInformacion);
    setNecesitaAceptarTerminos(usuario.necesitaAceptarTerminos);
    const storage = mantenerSesion ? localStorage : sessionStorage;
    storage.setItem("userDetails", JSON.stringify(usuario));
    if (!mantenerSesion) {
      actualizarLastActivity();
    }

    const rol = usuario.rol;
    if (redireccionar) {
      if (rol === "PRODUCTOR") {
        window.location.href = "/panel-productor";
      } else if (rol === "STAFF" || rol === "CAJERO" || rol === "RRPP") {
        window.location.href = "/mis-eventos";
      } else if (rol === "ADMIN") {
        window.location.href = "/panel-admin";
      } else {
        window.location.href = "/index";
      }
    }
  }, [actualizarLastActivity]);

  useEffect(() => {
    if (!localStorage.getItem("userDetails")) {
      const handleActivity = () => actualizarLastActivity();
      const eventosDeActividad = ["click", "mousemove", "keypress", "scroll", "touchstart"];
      eventosDeActividad.forEach(evento => window.addEventListener(evento, handleActivity));
      return () => eventosDeActividad.forEach(evento => window.removeEventListener(evento, handleActivity));
    }
  }, [actualizarLastActivity]);

  useEffect(() => {
    if (!localStorage.getItem("userDetails")) {
      const checkInactivity = () => {
        const lastActivityTime = parseInt(sessionStorage.getItem("lastActivity"), 10);
        const now = new Date().getTime();
        if (now - lastActivityTime >= 7200000) {
          logout();
        }
      };

      const intervalId = setInterval(checkInactivity, 60000);
      return () => clearInterval(intervalId);
    }
  }, [logout]);

  return (
    <AuthContext.Provider value={{ jwt, estaAutenticado, necesitaCompletarInformacion, necesitaAceptarTerminos, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};