import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from "./EnviarQrsPage.module.scss";
import TiposDeTicketsComponent from '../../Components/EnvioDeQrForm/TiposDeTicketsComponent';
import variables from "../../Context/Variables";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useAuth } from '../../Context/AuthProvider';

const EnviarQrsPage = () => {
    const { eventoId } = useParams();
    const [tiposDeTickets, setTiposDeTickets] = useState([]);
    const [nombreEvento, setNombreEvento] = useState('');
    const navigate = useNavigate();
    const [mostrarExplicacion, setMostrarExplicacion] = useState(false);
    const { jwt } = useAuth();
    const [esRRPP, setEsRRPP] = useState(false);

    useEffect(() => {
        document.title = "Generar y enviar QRs";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            navigate("/login");
        }
        const details = JSON.parse(userDetails);

        if (details.rol !== "PRODUCTOR" && details.rol !== "RRPP") {
            navigate("/login");
        }

        setEsRRPP(details.rol === "RRPP");
        
        axios.get(`${variables.API_BASE_URL}/api/eventos/${eventoId}/tiposDeTickets`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            }
        })
            .then(response => {
                if (response.data && response.data.tiposDeTickets) {
                    setTiposDeTickets(response.data.tiposDeTickets);
                    setNombreEvento(response.data.nombreEvento);
                }
            })
            .catch(error => console.error('Error al obtener los tipos de tickets:', error));
    }, [eventoId, navigate, jwt]);

    return (
      <div className={styles.body}>
        <div className={styles.detallesCont}>
          <div className={styles.headerRow}>
            <h2>Enviar QRs directamente</h2>
            {!esRRPP && (
              <button
                className={styles.redirectButton}
                onClick={() => navigate(`/panel-envios-qr/${eventoId}/${encodeURIComponent(nombreEvento)}`)}
              >
                <Icon width={14} icon="solar:checklist-bold" /> Ver registros de envíos
              </button>
            )}
          </div>
          <h3 className={styles.eventName}>Evento: {nombreEvento}</h3>
          <div className={styles.fondo}>
            <h3 onClick={() => setMostrarExplicacion(!mostrarExplicacion)} className={styles.comoFunciona}>¿Cómo funciona? {mostrarExplicacion ? <Icon icon="solar:alt-arrow-up-outline" /> : <Icon icon="solar:alt-arrow-down-outline" />}</h3>
            {mostrarExplicacion && (
              <>
                <p>
                  Desde aquí podés elegir el tipo de ticket que querés enviar; luego
                  especificarás los datos del receptor y la cantidad.
                </p>
                <p>
                  El envío de cada QR tiene un{" "}
                  <b>costo del 10% del valor del ticket.</b> Este se descontará del
                  total a liquidar. Por ejemplo, al enviar un ticket cuyo precio sin
                  recargos al público sea de $10.000, el costo del envío será de
                  $1.000.
                </p>
              </>
            )}
          </div>
        </div>
        <div className={styles.tipoDeTicketsCont}>
          <h4>Elige el ticket a enviar:</h4>
          <div className={styles.contTickets}>
            {tiposDeTickets.length > 0 && (
              <TiposDeTicketsComponent
                tiposDeTickets={tiposDeTickets}
                nombreEvento={nombreEvento}
                eventoId={eventoId}
              />
            )}
          </div>
        </div>
      </div>
    );
    
};

export default EnviarQrsPage;
