import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './GestionCajeros.module.scss';
import variables from "../../Context/Variables";
import Alert from '../../Components/Alert/Alert';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import { Icon } from "@iconify/react";
import CardColaborador from '../../Components/CardColaborador/CardColaborador';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const GestionCajeros = () => {
    const [cajeros, setCajeros] = useState([]);
    const [newCajeroEmail, setNewCajeroEmail] = useState('');
    const [newCajeroName, setNewCajeroName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [mostrarPassword, setMostrarPassword] = useState({});
    const [deletingCajeroIds, setDeletingCajeroIds] = useState(new Set());
    const navigate = useNavigate();
    const { estaAutenticado, jwt } = useAuth();

    useEffect(() => {
        document.title = "Gestión de Cajeros";
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!estaAutenticado) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);
            if (details.rol !== "PRODUCTOR") {
                return <NoPermissionPage />;
            }
            fetchCajeros(jwt);
        }
    }, [navigate, estaAutenticado]);

    const fetchCajeros = async (jwt) => {
        try {
            const response = await axios.get(`${variables.API_BASE_URL}/api/cajero/list`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            setCajeros(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching cajeros', error);
            setError('Failed to fetch cajeros.');
            setLoading(false);
        }
    };

    const deleteCajero = async (cajeroId) => {
        const result = await Alert({
            tipo: 'warning',
            titulo: '¿Estás seguro?',
            descripcion: "Esto eliminará al cajero seleccionado y todas sus asociaciones.",
            duracion: 0
        });

        if (result) {
            setDeletingCajeroIds(prev => new Set([...prev, cajeroId]));
            try {
                await axios.delete(`${variables.API_BASE_URL}/api/cajero/delete/${cajeroId}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                });

                await Alert({
                    tipo: 'success',
                    titulo: 'Eliminado!',
                    descripcion: 'El cajero ha sido eliminado exitosamente.',
                    duracion: 3000
                });

                setCajeros(cajeros.filter(member => member.id !== cajeroId));
            } catch (error) {
                await Alert({
                    tipo: 'error',
                    titulo: 'Error!',
                    descripcion: 'No se pudo eliminar el cajero.',
                    duracion: 4000
                });
            } finally {
                setDeletingCajeroIds(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(cajeroId);
                    return newSet;
                });
            }
        }
    };

    const addCajero = async () => {
        if (!validarEmail(newCajeroEmail)) {
            await Alert({
                tipo: 'warning',
                titulo: 'Email inválido',
                descripcion: 'Por favor, ingresa un email válido.',
                duracion: 3000
            });
            return;
        }

        if (!newCajeroName.trim()) {
            await Alert({
                tipo: 'warning',
                titulo: 'Nombre requerido',
                descripcion: 'Por favor, ingresa un nombre.',
                duracion: 3000
            });
            return;
        }

        setIsAdding(true);
        try {
            const response = await axios.post(`${variables.API_BASE_URL}/api/cajero/crear`, {
                email: newCajeroEmail,
                nombre: newCajeroName
            }, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            if (response.status === 200) {
                await Alert({
                    tipo: 'success',
                    titulo: 'Cajero creado',
                    descripcion: 'El cajero ha sido creado exitosamente.',
                    duracion: 3000
                });
                fetchCajeros(jwt);
                setNewCajeroEmail('');
                setNewCajeroName('');
            }
        } catch (error) {
            await Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: error.response?.data || 'Error al crear el cajero',
                duracion: 3000
            });
        } finally {
            setIsAdding(false);
        }
    };

    const validarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const togglePasswordVisibility = (cajeroId) => {
        setMostrarPassword(prevState => ({
            ...prevState,
            [cajeroId]: !prevState[cajeroId]
        }));
    };

    if (loading) {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
                <LoaderSimplepass />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Gestioná tus cajeros
                    <span>Agregá, eliminá y visualizá a todos los cajeros. Estos te ayudarán a gestionar las ventas en tus eventos.</span>
                </h1>
            </div>

            <div className={styles.addStaffSection}>
                <h2 className={styles.sectionTitle}>Agregar Cajero</h2>
                <div className={styles.addStaffForm}>
                    <div className={styles.inputGroup}>
                        <Icon icon="solar:user-bold" />
                        <input
                            type="text"
                            placeholder="Nombre del nuevo cajero"
                            value={newCajeroName}
                            onChange={e => setNewCajeroName(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <Icon icon="material-symbols:mail-outline" />
                        <input
                            type="email"
                            placeholder="Email del nuevo cajero"
                            value={newCajeroEmail}
                            onChange={e => setNewCajeroEmail(e.target.value)}
                        />
                    </div>
                    <button
                        onClick={addCajero}
                        disabled={isAdding}
                        className={styles.addButton}
                    >
                        {isAdding ? (
                            <Icon icon="line-md:loading-loop" />
                        ) : (
                            <>Agregar <Icon icon="mingcute:add-fill" /></>
                        )}
                    </button>
                </div>
            </div>

            <h2 className={styles.sectionTitle}>Tus Cajeros</h2>
            <div className={styles.staffList}>
                {cajeros.length > 0 ? (
                    cajeros.map(cajero => (
                        <CardColaborador
                            key={cajero.id}
                            colaborador={{
                                id: cajero.id,
                                username: cajero.nombre,
                                email: cajero.email,
                                contrasena: cajero.password
                            }}
                            onUnassign={deleteCajero}
                            isUnassigning={deletingCajeroIds.has(cajero.id)}
                            showPassword={mostrarPassword[cajero.id]}
                            onTogglePassword={() => togglePasswordVisibility(cajero.id)}
                            isInTeamManagement={true}
                        />
                    ))
                ) : (
                    <p className={styles.noStaff}>Aún no tienes cajeros.</p>
                )}
            </div>
        </div>
    );
};

export default GestionCajeros; 